import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import { Stack } from "@mui/material";
import { Modal } from "@material-ui/core";

const WatchDemo = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleRemoveWidget = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="h-[64px] w-[390px] fixed bottom-0 right-24 z-0 bg-white flex items-center shadow-live-shadow rounded-t-2xl pl-6 pb-4 pt-5 gap-x-36">
      <button
        onClick={handleOpenModal}
        className="text-black dark:text-white text-[18px] font-bold"
      >
        Watch Video Demo
      </button>

      <AiOutlineClose
        onClick={handleRemoveWidget}
        className="text-[#de2a2a] cursor-pointer"
        size={18}
      />
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Stack
          direction="column"
          alignItems="center"
          sx={{ height: "100vh" }}
          justifyContent="center"
        >
          <div className=" h-[512px] w-[420px] px-[20px] flex flex-col rounded-[11px] justify-end gap-y-4 pt-[40px] pb-[20px] bg-white relative">
            <AiOutlineClose
              onClick={handleCloseModal}
              className="absolute top-7 right-9 text-[#de2a2a] cursor-pointer"
              size={24}
            />
            <div className="pl-2 ">
              <p className="text-base text-A6A justify-start text-start pb-2">
                If you need assistance with the tutorial for navigating
                SuprSync, please let us know.
              </p>
              <button
                // onClick={(e) => {}}
                className="bg-base-color w-fit self-start cursor-pointer rounded-[3px] text-[white] p-3"
              >
                Watch Tutorial
              </button>
            </div>
          </div>
        </Stack>
      </Modal>
    </div>
  );
};

export default WatchDemo;
