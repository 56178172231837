/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import LinearProgress from '@mui/material/LinearProgress';

import {
  getBranchesAsync,
  fetchJobRolesAsync,
  getTeammemberAsync,
  scheduleGoogleSyncAsync,
  scheduleMSSyncSyncAsync,
} from '../../../../../Store/Branches';
import { getDatesArray } from '../../../../../utils/dateArray';
import getDates from '../../../../../utils/rangeOfData';
import DateLabel from '../../Widget/DateLabel/DateLabel';
import { transformScheduleData } from '../../../../../utils/handleScheduleData';
import {
  createAssigneeShiftslotNoLoadingAsync,
  createStaffShiftAsync,
  getAllJobGroupAsync,
  getShiftScheduleNoLoadingAsync,
  setWeeklyDateLabel,
  updateLimoScheduleAsync,
} from '../../../../../Store/Shift';
import moment from 'moment';
import ScheduleDayView from './components/ScheduleDayView';
import InitialsGen from '../../../../Widgets/InitialsGen';
import { Loader } from '../../../../Widgets/Loader';
import { getShiftTemplateAsync } from '../../../../../Store/Shift';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import { CreateShiftSlot } from '../ShiftSlotPage/Widgets/CreateShift/CreateShiftSlot';
import getDay from 'date-fns/getDay';
import { toast } from 'react-toastify';
import {
  cancelScheduleAsync,
  enableShiftSwapAsync,
  cancelScheduleNoLoadingAsync,
} from '../../../../../Store/Shift';
import PopDetail from '../../../../Widgets/Details/PopDetail';
import UserDetail from '../../../../Widgets/Details/UserDetail';
import { AnimatePresence, motion } from 'framer-motion';
import { OutlinedButton } from '../../../../Widgets/FormUtils/FormUtils';
import { Storage } from '../../../../../Network/StorageClass/StorageClass';
import swapPic from '../../../../../Assets/swap_notification.png';
import MakeOfferView from './components/makeOfferView';
import { months } from '../BranchSchedule/mock';
import { LocationStackView } from './components/StackView';
import { BsSearch } from 'react-icons/bs';
import { CiSearch } from 'react-icons/ci';
import { useLocation, useNavigate } from 'react-router';
import SyncIcon from '../../../../../Assets/sync.svg';
import { GoogleConnectModal } from './components/GoogleConnectModal';
import AppLayout from '../../../../Widgets/AppLayout';
import { TeamMemberDetails } from '../../../BranchScreen/Tabs/TeamMembers/TeamMembersDetails/TeamMembersDetails';
import { adminClockInOutUser } from '../../../../../Network/ServiceClass/ShiftService';
import NameEntryView from './components/NameEntryView';
import TutorialPanel from '../../../../Widgets/Tutorial/TutorialPanel';
import { scheduleRoles } from './mock';
import RequestTimeOff from '../TimeOff/Widgets/RequestTimeOff/RequestTimeOff';
import { fetchTimeOffThunk } from '../../../../../Store/timeOff';
import MultipleSelectDropdown from '../../../../Widgets/MultiDropdown';
import generateCalendarDates from '../../../../../utils/generateScheduleDate';
import CustomNavigateCalender from '../../Widget/CustomNavigateCalendar/CustomNavigateCalendar';
import RotationView from './components/RotationView';
import { ConfirmShiftModal } from './components/ConfirmShift';
import tinycolor from 'tinycolor2';

const storage = new Storage();

const getMonth = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12',
};

export const Schedules = () => {
  const userId = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user')).id
    : '';
  const currentRotationNumber = useSelector((state) => state?.company)?.currentRotationNumber;
  const params = useLocation();
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [confirmShift, setConfirmShift] = useState(false);
  const [openConfirmShift, setOpenConfirmShift] = useState(false);
  const shiftTempData = useRef({});
  const [userIds, setUserId] = useState('');
  const [calendarDisplay, setCalendar] = useState([]);
  const [selectedPTODate, selectPTODate] = useState('');
  const [openPTO, setPTOMode] = useState(false);
  const [jobroleList, setJobrolelist] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [branchSelected, selectBranch] = useState(undefined);
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [ongoingState, setOngoing] = useState([]);
  const [progressState, setProgressState] = useState([]);
  const [isOpenSlotOpen, openSlots] = useState(false);

  const [isLoadingClocking, setIsLoadingClocking] = useState(false);
  const [loaderLoading, setLoader] = useState(false);
  
  const [nameEntryInput, setNameEntryInput] = useState('');
  const [isBranchhover, setIsHover] = useState(false);

  const [openGoogle, setGoogleModal] = useState(false);
  const loggedInUserDetails = storage.getUserDetails();
  const dispatch = useDispatch();
  const [scheduleDate, setScheduleDate] = useState(
    moment()?.format('yyyy-MM-DD')
  );
  const [displayMode, setDisplayMode] = useState({
    value: 'rotation-view',
    label: 'Rotation view',
  });

  const [selectedShiftBranch, setShiftBranch] = useState({
    label: 'All Shift locations',
    value: '',
  });

  const [selectedMultiBranch, setMultiBranch] = useState([]);

  const [openShiftSlot, setOpenShiftSlot] = useState(false);
  const [selectedMember, selectMember] = useState(null);
  const [selectedSchedule, selectSchedule] = useState('');
  const [inputSearchBranch, setInputSearchBranch] = useState('');
  const [monthSelected, setMonth] = useState({
    label: moment().format('MMMM'),
    value: getMonth[moment().format('MMMM')],
  });

  const [nameEntrymonthSelected, setNameEntryMonth] = useState({
    label: moment().format('MMMM'),
    value: getMonth[moment().format('MMMM')],
  });

  const [selectedRoleTag, setRoleTag] = useState({
    label: 'All role tags',
    value: '',
  });

  const [selectedROT, setROT] = useState({
    label: `ROT ${currentRotationNumber}`,
    value: currentRotationNumber,
  });

  const [selectedJobRole, setJobRole] = useState({
    label: 'All job roles',
    value: '',
  });
  const [yearSelected, setYearSelected] = useState({
    label: moment().format('yyyy'),
    value: moment().format('yyyy'),
  });
  const [selectedTemplate, selectTemplate] = useState({
    label: 'Default Shift Template',
    value: '',
  });

  // eslint-disable-next-line no-unused-vars
  const [shiftSlotData, setShiftSlotData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openPopper, setOpenPopper] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [popperHovered, setPopperHovered] = useState(false);
  const [openPopperShiftDetails, setOpenPopperShiftDetails] = useState(null);
  const [saveTarget, setSaveTarget] = useState(null);
  const [shiftInfo, setShiftInfo] = useState(null);
  const [popperHoveredShiftDetails, setPopperHoveredShiftDetails] =
    useState(false);
  const privilege =
    useSelector((state) => state?.company)?.privilege || 3;  

  const branches =
    useSelector((state) => state?.branches)?.branches?.data || [];
  const jobRoles = useSelector((state) => state?.branches)?.jobRoles;
  let shiftSchedules = useSelector((state) => state?.shift)?.schedules;
  shiftSchedules = Array.isArray(shiftSchedules)
    ? shiftSchedules?.filter((x) => {
        if (!selectedShiftBranch?.value) {
          return true;
        } else {
          return x?.branchId?.includes(selectedShiftBranch?.value);
        }
      })
    : [];

  const jobTags = useSelector((state) => state?.shift)?.roleTags;
  const loading = useSelector((state) => state?.shift)?.loading;
  const limboLoading = useSelector((state) => state?.shift)?.limboLoading;
  const cellLoading = useSelector((state) => state?.shift)?.cellLoading;
  const viewChanging = useSelector((state) => state?.shift)?.viewChanging;
  const linkingLoading = useSelector((state) => state.branches)?.loading;
  const teamMembers = useSelector((state) => state?.branches)?.teamMembers;
  const memberships = useSelector((state) => state?.company)?.activeCompany
    ?.memberships;
  const isConnected = useSelector((state) => state?.branches)?.connected;

  const company = useSelector((state) => state?.company)?.activeCompany;
  const rotationNumber = useSelector((state) => state?.company)?.rotationNumber;
  
  const timeoff = useSelector((state) => state?.timeOff)?.timePTO;

  const dateCreated = useSelector(
    (state) => state?.company?.activeCompany?.createdOn
  );

  const shiftTemplates =
    useSelector((state) => state?.shift)?.shiftTemplates || [];
  const roleTags = useSelector((state) => state?.shift)?.roleTags;

  const [mode, setMode] = useState('calendar');
  const shiftStateWeeklyDateLabel = useSelector(
    (state) => state.shift.weeklyDateLabel
  );

  const handleSearchBranch = (val) => {
    if (val === '') return branches;

    const pattern = new RegExp(`\D*${val}\D*`, 'i');
    return branches.filter((branch) => branch.name.match(pattern));
  };

  const dates =
    (shiftStateWeeklyDateLabel?.from &&
      shiftStateWeeklyDateLabel?.to &&
      getDates(
        shiftStateWeeklyDateLabel?.from,
        shiftStateWeeklyDateLabel?.to
      )) ||
    [];

  let scheduleFilter =
    localStorage.getItem('@schedulefilter') &&
    JSON.parse(localStorage.getItem('@schedulefilter'));

  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(fetchJobRolesAsync());
    dispatch(getTeammemberAsync({}));
    dispatch(getShiftTemplateAsync());
    dispatch(getAllJobGroupAsync());
    dispatch(fetchTimeOffThunk());

    if (scheduleFilter) {
      const existingLocation = JSON.parse(
        localStorage.getItem('@schedulefilter')
      );
      setMultiBranch(existingLocation?.assignLocation);
      setJobRole({ ...selectedJobRole, ...existingLocation?.jobRole });
      setRoleTag({ ...selectedRoleTag, ...existingLocation?.roleTag });
      selectTemplate({
        ...selectedTemplate,
        ...existingLocation?.shiftTemplate,
      });
      setShiftBranch({
        ...selectedShiftBranch,
        ...existingLocation?.shiftLocation,
      });
    }

    const generateddates = generateCalendarDates(
      yearSelected.value,
      monthSelected.value
    );
    setCalendar(generateddates);
  }, []);

  useEffect(()=>{
    const generateddates = generateCalendarDates(
      yearSelected.value,
      monthSelected.value
    );
    setCalendar(generateddates);
  },[monthSelected,yearSelected])

  useEffect(() => {
    setPage(0);
  }, [selectedRoleTag]);

  const handleMultiSelect = (val) => {
    if (scheduleFilter) {
      scheduleFilter = {
        ...scheduleFilter,
        assignLocation: val,
      };
      localStorage.setItem('@schedulefilter', JSON.stringify(scheduleFilter));
    } else {
      localStorage.setItem(
        '@schedulefilter',
        JSON.stringify({ assignLocation: val })
      );
    }
    setMultiBranch(val);
  };

  const handleOnMonthChange = (data) => {
    setMonth(data);
  };

  const handleOnChangeNamEntryMonth = (data) => {
    setNameEntryMonth(data);
  };

  const handleCloseDropdown = () => {
    setJobrolelist([]);
    setOpenDropdown(false);
  };

  // const handleOpenDropdown = () => {
  //   setJobrolelist([]);
  //   setOpenDropdown(true);
  // };

  const handleToggleDropdown = (e = undefined) => {
    setJobrolelist([]);
    if (!openDropdown || e !== selectedSchedule)
      setOpenDropdown((prev) => !prev);
  };

  const handleSchedule = (branch) => {
    let filterBranches = branches;
    let filterTeamMembers = teamMembers;
    // const scheduleUser = shiftSchedules?.map(
    //   (shiftSchedule) => {
    //     return shiftSchedule?.user?.id;
    //   }
    // )
    // if both job role and branch is selected
    if (branch?.length > 0) {
      filterTeamMembers = teamMembers?.filter((x) =>
        branch?.map((y) => y.value)?.includes(x?.assignedBranchId)
      );
      // filterBranches = branches?.filter((x) => x.id.includes(branch || ''));
    }

    if (selectedRoleTag?.value?.length > 0) {
      const jobRoleId = jobRoles
        .filter((job) =>
          job.roleTags
            .map((v) => v?.tagInfo?.id)
            ?.includes(selectedRoleTag?.value)
        )
        ?.map((x) => x.id);
      filterTeamMembers = teamMembers.filter((t) =>
        jobRoleId.includes(t?.jobRole?.id || '')
      );
    }

    if (selectedJobRole?.value?.length > 0) {
      filterTeamMembers = filterTeamMembers?.filter((x) =>{
        const secondaryRoles = x?.secondaryJobroles?.map((y) => y.jobroleId)
        const roles = [x?.jobRole?.id, ...secondaryRoles]
        return roles?.includes(selectedJobRole?.value || '')
      }
      );
    }

    if (searchText.length >= 3) {
      const pattern = new RegExp(`\D*${searchText}\D*`, 'i');
      filterTeamMembers = teamMembers?.filter(
        (x) =>
          x?.user?.firstName?.match(pattern) || x?.user?.lastName.match(pattern)
      );
    }

    return transformScheduleData({
      teamMembers: filterTeamMembers,
      branches: filterBranches,
    });
  };

  const handleOpenUserProfile = (data) => {
    navigate(`/branches/${data?.branchId}/${data?.user?.profileInfo?.id}`, {
      state: {
        branchName: data?.branchName,
        data: data?.user?.profileInfo
      }
    })
    setSelectedUserProfile(data);
    setOpenPopper(null);
  };

  const handlePageLoader = (val) => {
    setLoader(val);
  };

  const handleClose = () => {
    setOpenShiftSlot(false);
  };

  const handleGetShiftSchedule = (setLoading = true) => {
    if (setLoading) {
      handlePageLoader(setLoading);
    }
    if (shiftStateWeeklyDateLabel.from) {
      dispatch(
        getShiftScheduleNoLoadingAsync({
          roleTagId: selectedRoleTag?.value,
          jobRoleId: selectedJobRole?.value,
          branchId: selectedShiftBranch?.value,
          userId:
            selectUserSchedule().length > 1 ? selectUserSchedule() : undefined,
          from: moment(shiftStateWeeklyDateLabel.from)
            .add(0, 'minutes')
            .add(0, 'hours')
            .format('yyyy-MM-DDTHH:mm'),
          to: moment(shiftStateWeeklyDateLabel.to)
            .add(59, 'minutes')
            .add(23, 'hours')
            .format('yyyy-MM-DDTHH:mm'),
        })
      ).then((res) => {
        handlePageLoader(false);
      });
    }
  };

  const handleClockInOut = (type, id) => {
    setIsLoadingClocking(true);
    adminClockInOutUser({ data: { type }, id }).then((val) => {
      setIsLoadingClocking(false);
      if (val?.status === 200) {
        handleGetShiftSchedule(false);
        toast.success('Success');
      } else {
        toast.error(val?.data?.message);
      }
      handlePopperMouseLeaveShiftDetails();
    });
  };

  const handleGetStackShiftSchedule = () => {
    handlePageLoader(true);
    const endDate = moment(
      `${yearSelected.value}-${monthSelected.value}`,
      'yyyy-MM-DD'
    ).daysInMonth();

    dispatch(
      getShiftScheduleNoLoadingAsync({
        roleTagId: selectedRoleTag?.value,
        jobRoleId: selectedJobRole?.value,
        branchId: selectedShiftBranch?.value,
        userId:
          selectUserSchedule()?.length > 1 ? selectUserSchedule() : undefined,
        from: moment(
          `${yearSelected.value}-${monthSelected.value}-01`,
          'yyyy-MM-DD'
        )
          .add(0, 'hours')
          .format('yyyy-MM-DDTHH:mm'),
        to: moment(
          `${yearSelected.value}-${monthSelected.value}-${endDate}`,
          'yyyy-MM-DD'
        )
          .add(0, 'hours')
          .format('yyyy-MM-DDTHH:mm'),
      })
    ).then(() => {
      handlePageLoader(false);
    });
  };

  useEffect(() => {
    if (displayMode.value === 'stack-view') {
      handleGetStackShiftSchedule();
    }
  }, [monthSelected.value, displayMode.value]);

  useEffect(() => {
    handlePageLoader(true);
    if (mode === 'calendar') {
      if (['rotation-view', 'name-entry-view'].includes(displayMode.value)) {
        handleOnChangeROT(selectedROT);
      }

      if (displayMode.value === 'date-picker') {
        handleGetShiftSchedule();
      }

      // if (displayMode.value === 'name-entry-view') {
      //   handleOnChangeNameEntry();
      // }
    }
    handlePageLoader(false);
  }, [
    shiftStateWeeklyDateLabel.from,
    selectedMultiBranch?.length,
    selectedShiftBranch?.value,
    nameEntrymonthSelected?.value,
    yearSelected.value,
    displayMode.value,
    teamMembers,
    selectedRoleTag?.value,
    rowsPerPage,
    mode,
    userIds,
    offset,
  ]);

  const handleSelectShiftBranch = (e) => {
    if (scheduleFilter) {
      scheduleFilter = {
        ...scheduleFilter,
        shiftLocation: e,
      };
      localStorage.setItem('@schedulefilter', JSON.stringify(scheduleFilter));
    } else {
      localStorage.setItem(
        '@schedulefilter',
        JSON.stringify({ shiftLocation: e })
      );
    }
    setShiftBranch(e);
  };

  const handleSelectRoleTag = (e) => {
    if (scheduleFilter) {
      scheduleFilter = {
        ...scheduleFilter,
        roleTag: e,
      };
      localStorage.setItem('@schedulefilter', JSON.stringify(scheduleFilter));
    } else {
      localStorage.setItem('@schedulefilter', JSON.stringify({ roleTag: e }));
    }
    setRoleTag(e);
  };

  const handleJobRole = (e) => {
    if (scheduleFilter) {
      scheduleFilter = {
        ...scheduleFilter,
        jobRole: e,
      };
      localStorage.setItem('@schedulefilter', JSON.stringify(scheduleFilter));
    } else {
      localStorage.setItem('@schedulefilter', JSON.stringify({ jobRole: e }));
    }
    setJobRole(e);
  };

  const handleOnDateChange = (data) => {
    setYearSelected(data);
  };

  const handleDateSelection = (e) => {
    setScheduleDate(e.target.value);
  };

  const handleSelectedShiftTemplate = (e) => {
    if (scheduleFilter) {
      scheduleFilter = {
        ...scheduleFilter,
        shiftTemplate: e,
      };
      localStorage.setItem('@schedulefilter', JSON.stringify(scheduleFilter));
    } else {
      localStorage.setItem(
        '@schedulefilter',
        JSON.stringify({ shiftTemplate: e })
      );
    }
    selectTemplate(e);
  };

  const handleOpenScheduleOption = (e) => {
    selectSchedule(e);
  };

  const handleSelectedBranch = (user) => {
    const conv = user?.secondaryJobroles?.map((x) => x?.jobrole) || [];
    const newJobRoles = [user?.jobRole, ...conv];
    setJobrolelist(newJobRoles);
  };

  const handleConfirmShift = (
    e,
    date,
    user,
    filledIn = undefined,
    toggle = true,
    cb = () => {},
    openInput = true
  ) => {
    shiftTempData.current = {
      e,
      date,
      user,
      toggle,
      cb: () => {},
      openInput
    }
    setConfirmShift(true)
    setOpenConfirmShift(true);
  }

  const handleCloseConfirmInfo = () => {
    setConfirmShift(false);
    setOpenConfirmShift(false);
  }

  const handleCreateShift = (
    e,
    date,
    user,
    filledIn = undefined,
    toggle = true,
    cb = () => {},
    openInput = true
  ) => {

    // if(moment(date).isBefore(moment())){
    //   toast.error('You cannot create a schedule in the past')
    //   setOpenConfirmShift(false);
    //   return
    // }
    
    if(!confirmShift && shiftTemplates.length >1 && selectedTemplate.value === ''){
      handleConfirmShift(e,date,user, undefined,toggle,cb,openInput)
      return;
    }
      setProgressState((prev) => [...prev, date]);

    if (selectedTemplate.value !== '') {
      if(!!!selectedTemplate?.daysOfWeek){
        toast.error('Please update the days of week for this shift type schedule')
        return
      }

      // number of shifts schedule for that branch
      const numberOfSchedule = shiftSchedules?.filter(
        (x) =>
          (x?.branchId === e?.id) &&
          (moment(x?.start).format('yyyy-MM-DD') ===
            moment(date).format('yyyy-MM-DD')) && x?.slotjobRole?.id === selectedTemplate?.jobId
      ).length;

      const maxStaffRequired = selectedTemplate.locations.find((x) => x?.location.id === e.id)


      if (!!!maxStaffRequired){
        toast.error('This shift template cannot be used in this location')
        return
      }
      const remainingSlots = (maxStaffRequired?.staffRequired || 0) - numberOfSchedule;

      if (remainingSlots <= 0){
        toast.error(`You have exceeded the maximum required staff for this location for ${moment(date).format('do MMM, yyyy')}`)
        return
      }

      if(selectedTemplate?.daysOfWeek.split(',')[moment(date).day()] === '0'){
        toast.error(`You cannot create a shift for the selected day of the week for this shift template`)
        return
      }
    }

    // let selectedTimeData = '09:00-17:00';
    if (e?.index !== undefined) {
      setOngoing((prev) => [...prev, `${user.id}${e.index}`]);
    }
    let startTime = '09:00';
    let endTime = '17:00';
    let hours = 0;
    let jobId = user?.jobRole?.id;
    let maxStaffRequired = 1;
    const assignees = [{ userId: user.id, userName: user.name }];

    if (selectedTemplate?.value !== '') {
      const userJobRole = [
        user?.jobRole.id,
        ...user.secondaryJobroles.map((x) => x.jobroleId),
      ];
      user.secondaryJobroles.map((x) => x.jobroleId);
      const [filteredData] = shiftTemplates?.filter(
        (x) => x.id === selectedTemplate?.value
      );
      startTime = filteredData?.startTime;
      endTime = filteredData?.endTime;
      hours = filteredData?.hours;
      jobId = filteredData?.jobRole?.id;
      maxStaffRequired = filteredData?.maxStaffRequired;

      if (!userJobRole.includes(filteredData?.jobRole?.id)) {
        return toast.error(
          `The user doesn't have a jobrole and secondary job role of ${filteredData?.jobRole?.title}`
        );
      }
    }

    var start = moment.utc(startTime, 'HH:mm');
    var end = moment.utc(endTime, 'HH:mm');
    // if(toggle){
    //     selectSchedule(null);
    //   }

    if(openInput){
      setOpenDropdown(false);
    }

    const data = {
      branchId: e?.id,
      jobRoleId: jobId,
      assignees,
      dayOfWeek: (date && getDay(moment(date).toDate())) || 0,
      startTime,
      fillInRole: filledIn,
      bookDate: date && moment(date).format('yyyy-MM-DD'),
      hours: hours || end.diff(start, 'hours'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      maxStaffRequired: maxStaffRequired,
    };

    setOpenConfirmShift(false);



    if([1,2].includes(privilege)){
      dispatch(createAssigneeShiftslotNoLoadingAsync(data)).then((res) => {
        if (![200, 201].includes(res?.payload?.status)) {
          toast.error(res?.payload?.data?.message);
          return;
        }
  
        if (res?.payload?.data?.slotBookings?.err?.length > 0) {
          toast.error(res?.payload?.data?.slotBookings?.err[0]?.reason);
          return;
        }
  
        if (['rotation-view', 'name-entry-view'].includes(displayMode.value)) {
          handleOnChangeROT(selectedROT, false);
        } else {
          handleGetShiftSchedule(false);
        }
  
        // if(toggle){
        //   selectSchedule(null);
        // }
        setConfirmShift(false)
        cb();
        toast.success('Your shift has been created successfully');
        const currxState = ongoingState.filter(
          (x) => x !== `${user.id}${e.index}`
        );
        const currState = progressState.filter((x) => x !== date);
        setProgressState(currState);
        setOngoing(currxState);
      });
    } else {
      dispatch(createStaffShiftAsync({
        branchId: e?.id,
        jobRoleId: jobId,
        dayOfWeek: (date && getDay(moment(date).toDate())) || 0,
        startTime,
        bookDate: date && moment(date).format('yyyy-MM-DD'),
        hours: hours || end.diff(start, 'hours'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        maxStaffRequired: maxStaffRequired,
      })).then(res => {
        if (![200, 201].includes(res?.payload?.status)) {
          toast.error(res?.payload?.data?.message);
          return;
        }
  
        if (res?.payload?.data?.slotBookings?.err?.length > 0) {
          toast.error(res?.payload?.data?.slotBookings?.err[0]?.reason);
          return;
        }
  
        if (['rotation-view', 'name-entry-view'].includes(displayMode.value)) {
          handleOnChangeROT(selectedROT, false);
        } else {
          handleGetShiftSchedule(false);
        }
  
        // if(toggle){
        //   selectSchedule(null);
        // }
        setConfirmShift(false)
        cb();
        toast.success('Your shift has been created successfully');
        const currxState = ongoingState.filter(
          (x) => x !== `${user.id}${e.index}`
        );
        const currState = progressState.filter((x) => x !== date);
        setProgressState(currState);
        setOngoing(currxState);
      })
    }
  };

  const handleCancelSchedule = (id) => {
    const admins = memberships
      .filter((x) => [1, 2].includes(x.privilege))
      ?.map((x) => x.userId);
    dispatch(cancelScheduleAsync({ id, privileges: admins })).then((res) => {
      selectSchedule(null);
      if (res?.payload?.response?.status === 404) {
        toast.error(res?.payload?.response?.data?.message);
        return;
      }
      if (res?.meta?.requestStatus === 'fulfilled') {
        if (['rotation-view', 'name-entry-view'].includes(displayMode.value)) {
          handleOnChangeROT(selectedROT, false);
        } else {
          handleGetShiftSchedule(false);
        }
        toast.success('Your shift has been cancelled successfully');
      }
    });
  };

  const handleCreateScheduleEntry = (
    e,
    date,
    user,
    filledIn = undefined,
    cb = () => {}
  ) => {
    const admins = memberships
      .filter((x) => [1, 2].includes(x.privilege))
      ?.map((x) => x.userId);
    dispatch(
      cancelScheduleNoLoadingAsync({ id: e.shiftId, privileges: admins })
    ).then((res) => {
      if (res?.payload?.response?.status === 404) {
        toast.error(res?.payload?.response?.data?.message);
        return;
      }
      if (res?.meta?.requestStatus === 'fulfilled') {
        if (['rotation-view', 'name-entry-view'].includes(displayMode.value)) {
          handleOnChangeROT(selectedROT, false);
        } else {
          handleGetShiftSchedule(false);
        }
        handleCreateShift(e, date, user, (filledIn = undefined), false, cb, false);
      }
    });
  };

  const handleEnableShiftSwap = (id, swappable) => {
    const data = {
      shiftId: id,
      value: !swappable,
    };

    dispatch(enableShiftSwapAsync(data)).then((res) => {
      selectSchedule(null);
      if (res?.payload?.response?.status === 404) {
        toast.error(res?.payload?.response?.data?.message);
        return;
      }
      if (res?.meta?.requestStatus === 'fulfilled') {
        if (['rotation-view', 'name-entry-view'].includes(displayMode.value)) {
          handleOnChangeROT(selectedROT, false);
        } else {
          handleGetShiftSchedule(false);
        }
        toast.success(
          `Your shift has been ${swappable ? 'cancel' : 'open'} for swap`
        );
      }
    });
  };

  const handleOpenPTO = (userId) => {
    let user = {}
    if(localStorage.getItem('user')){
      user =JSON.parse(localStorage.getItem('user'))
    }
   
    if (
      user?.id === userId ||
      (memberships?.length > 0 && [1, 2].includes(memberships[0]?.privilege))
    ) {
      setPTOMode(true);
    }
  };

  const handleOpenPopper = (event, data) => {
    setOpenPopper(event.target);
    setUserInfo(data.user);
  };

  const handlePopperMouseEnter = () => {
    setPopperHovered(true);
  };

  const handlePopperMouseLeave = () => {
    setPopperHovered(false);
  };

  const handleOpenPopperShiftDetails = (event, data) => {
    !isLoadingClocking && setOpenPopperShiftDetails(event.target);
    setSaveTarget(event.target);
    !isLoadingClocking && setShiftInfo(data);
  };

  const handlePopperMouseEnterShiftDetails = () => {
    setPopperHoveredShiftDetails(true);
  };

  const handlePopperMouseLeaveShiftDetails = () => {
    setPopperHoveredShiftDetails(false);
  };

  const mainAnim = {
    exit: {
      opacity: 0,
      transition: { ease: 'easeOut', duration: 1 },
    },
  };

  const selectUserSchedule = () => {
    let data = handleSchedule(selectedMultiBranch)?.slice(
      offset,
      offset + rowsPerPage
    );
    if (displayMode.value === 'stack-view') {
      data = handleSchedule(selectedMultiBranch);
    }
    setUserId(
      [
        userId,
        ...data?.filter((y) => y.user.id !== userId)?.map((x) => x?.user?.id),
      ].join(',')
    );
    return [
      userId,
      ...data?.filter((y) => y.user.id !== userId)?.map((x) => x?.user?.id),
    ].join(',');
  };

  const getBackgroundColor = (data, date) => {
    if (shiftSchedules?.length > 0) {
      const cellSchedule = shiftSchedules?.filter((shiftSchedule) => {
        return (
          moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
            moment(date).format('yyyy-MM-DD') &&
          data?.user?.id === shiftSchedule?.user?.id
        );
      });

      if (cellSchedule?.length > 0) {
        return branches?.filter(
          (mybranch) => mybranch.id === cellSchedule[0].slot?.branch?.id
        )[0]?.hexcode !== 'Null'
          ? branches?.filter(
              (mybranch) => mybranch.id === cellSchedule[0].slot?.branch?.id
            )[0]?.hexcode
          : 'white';
      }
      return '';
    }
    return '';
  };

  const handleOnChangeROT = (e, load = true) => {
    setROT(e);
    handlePageLoader(load);
    const rotationStart = company?.rotationStart;
    const startDays = 1 + company?.rotationDays * (e.value - 1);
    const endDays = startDays + (company?.rotationDays - 1);
    dispatch(
      setWeeklyDateLabel({
        from: moment(rotationStart).add(startDays, 'days').format('yyyy-MM-DD'),
        to: moment(rotationStart).add(endDays, 'days').format('yyyy-MM-DD'),
      })
    );

    if (shiftStateWeeklyDateLabel.from) {
      dispatch(
        getShiftScheduleNoLoadingAsync({
          rotationNumber: selectedROT.value,
          userId:
            selectUserSchedule()?.length > 1 ? selectUserSchedule() : undefined,
          from: moment(rotationStart)
            .add(startDays, 'days')
            .format('yyyy-MM-DD'),
          to: moment(rotationStart).add(endDays, 'days').format('yyyy-MM-DD'),
        })
      ).then(() => {
        handlePageLoader(false);
      });
    }
  };

  const [openMakeOfferViewModal, setOpenMakeOfferViewModal] = useState(null);
  const handleOpenMakeOfferViewModal = (val) => {
    setOpenMakeOfferViewModal(val);
  };
  const handleCloseMakeOfferViewModal = () => {
    setOpenMakeOfferViewModal(null);
    setSaveTarget(null);
  };

  const handleViewDisplay = (e) => {
    setDisplayMode(e);
  };

  const handleRoleTags = (id) => {
    let roleTags = jobRoles.filter((x) => x.id === id)[0]?.roleTags;

 
    if (!roleTags || roleTags?.length === 0 || ((roleTags?.length ===1) && !roleTags[0]?.tagInfo) || !handleGenerateTags()?.map((x) => x.id).includes(roleTags[0].tagInfo?.id)) {
      roleTags = [
        {
          tagInfo: {
            id: 'other',
          },
        },
      ];
    }

    

    // handleGenerateTags()
    // if(handleGenerateTags()?.map((x) => x.id).includes(roleTags[0].tagInfo?.id))
    return roleTags;
  };

  const handleScheduleToGoogle = () => {
    dispatch(
      scheduleGoogleSyncAsync({
        membershipId: memberships?.length > 0 && memberships[0]?.id,
      })
    ).then((res) => {
      if (res?.meta?.requestStatus === 'fulfilled') {
        toast.success('Google scheduling was successful');
      } else {
        toast.error('Google scheduling was not successful');
      }
    });
  };

  const handleScheduleToOutLook = () => {
    dispatch(
      scheduleMSSyncSyncAsync({
        membershipId: memberships?.length > 0 && memberships[0]?.id,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    ).then((res) => {
      if (res?.meta?.requestStatus === 'fulfilled') {
        toast.success('Google scheduling was successful');
      } else {
        toast.error('Google scheduling was not successful');
      }
    });
  };

  const handleCloseGoogleModal = () => {
    setGoogleModal(false);
    if (params?.search) {
      navigate('/schedules');
    }
  };

  const handleChangePage = (event, newPage) => {
    const newOffset = newPage * rowsPerPage;
    setOffset(newOffset);
    setPage(newPage);
    selectUserSchedule();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    selectUserSchedule();
  };

  const handleGenerateTags = () => {
    return [...jobTags, { name: 'Others', id: 'other', hexcode: '#808080' }];
  };

  const handleCheckPTOStatus = (userId, date) => {
    const getPTOForTheDate = timeoff?.filter(
      (x) =>
        x.requester.id === userId &&
        moment(moment(date).format('yyyy-MM-DD')).isBetween(
          moment(x.start).subtract(1, 'days').format('yyyy-MM-DD'),
          moment(x.end).add(1, 'days').format('yyyy-MM-DD')
        )
    );
    return getPTOForTheDate?.length > 0;
  };

  const handleCellRender = (cond = true, tags) => {
    return Array.from(handleSchedule(selectedMultiBranch))
      ?.slice(offset, offset + rowsPerPage)
      .filter((x) => (cond ? x?.user.id !== userId : x?.user.id === userId))
      ?.filter(
        (x) =>
          handleRoleTags(x?.user?.jobRole?.id)?.map(
            (y) => y?.tagInfo?.id
          )[0] === tags.id
      );
  };

  const handleTotalSlot = () => {
    return (
      selectedTemplate?.locations?.reduce(
        (prev, curr) => prev + (curr?.staffRequired || 0),
        0
      ) || 0
    );
  };

  const handleRelevantSlot = () => {
    const assignLocationIds = selectedMultiBranch?.map((x) => x.value);
    if (!!!assignLocationIds?.length) {
      return (
        selectedTemplate?.locations?.reduce(
          (prev, curr) => prev + (curr?.staffRequired || 0),
          0
        ) || 0
      );
    }
    return (
      selectedTemplate?.locations
        ?.filter((loc) => assignLocationIds.includes(loc?.location?.id))
        ?.reduce((prev, curr) => prev + (curr?.staffRequired || 0), 0) || 0
    );
  };

  const handleCalShiftNo = (date, filterBranch = false) => {
    const assignLocationIds = selectedMultiBranch?.map((x) => x.value);
    let scheduleArr = shiftSchedules?.filter((shiftSchedule) => {
      return (
        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
        moment(date).format('yyyy-MM-DD')
      );
    });

    if (filterBranch) {
      scheduleArr = scheduleArr?.filter((y) =>
        assignLocationIds?.includes(y?.branchId)
      );
    }

    return scheduleArr?.length;
  };

  const calFirstTrack = (date) => {
    return handleCalShiftNo(date) - handleTotalSlot();
  };
  const calSecondTrack = (date) => {
    return handleCalShiftNo(date, true) - handleRelevantSlot();
  };

  const handleRenderScheduleCell = (schedule, lastBorder = true, isAdmin=true) => {
    return (
      schedule?.mode === 'data' && (
        <tr>
          <td
            className={`border-l ${
              lastBorder ? `` : 'border-b'
            }  px-2 flex items-center py-3 sticky left-0 bg-white dark:bg-138 dark:border-333 ${
              displayMode.value === 'rotation-view' ? 'left-[250px] z-20' : ''
            }`}
            onMouseEnter={(event) => {
              let timeoutId = setTimeout(() => {
                handleOpenPopper(event, schedule);
              }, 500);
              event.currentTarget.addEventListener('mouseleave', () => {
                clearTimeout(timeoutId);
              });
            }}
            onMouseLeave={() => setOpenPopper(null)}
          >
            <div
              className="cursor-pointer"
              onClick={() => handleOpenUserProfile(schedule)}
            >
              {schedule?.user?.imageUrl ? (
                <img
                  className="rounded-full h-10 w-10 mr-2"
                  src={schedule?.user?.imageUrl}
                  alt="logo"
                />
              ) : (
                <InitialsGen
                  width={40}
                  name={schedule?.user?.name}
                  bgColor="#00AD57"
                />
              )}
            </div>
            <div>
              <p className="font-bold text-xs text-555 dark:text-white">
                {schedule?.user?.name[0]}.{schedule?.user?.name?.split(' ')[1]}
                {schedule?.user?.skills?.filter((x) => x?.skillSetCode !== '')
                  ?.length > 0 &&
                  schedule?.user?.skills?.map((x) => (
                    <span>
                      {' '}
                      (
                      {x?.skillSetCode?.length > 0
                        ? x?.skillSetCode
                        : x?.name?.slice(0, 2)?.toUpperCase()}
                      )
                    </span>
                  ))}
              </p>

              <div className="flex items-center gap-2">
                <p className="text-[9px] text-999 dark:text-white">
                  {schedule?.user?.jobRole?.title}
                </p>
                <div className="">
                  <div
                    style={{
                      backgroundColor:
                        schedule?.user?.jobRole?.hexcode !== 'NULL'
                          ? schedule?.user?.jobRole?.hexcode
                          : 'white',
                    }}
                    className={`w-[6px] h-[6px] rounded-full bg-[${
                      schedule?.user?.jobRole?.hexcode !== 'NULL'
                        ? schedule?.user?.jobRole?.hexcode
                        : 'white'
                    }]`}
                  />
                </div>
              </div>
            </div>
            {/* <div className="absolute right-2 top-4"><p className="text-sm font-bold dark:text-white">{shiftSchedules?.filter(
                (shiftSchedule) => {
                  return (
                    schedule?.user?.id ===
                      shiftSchedule?.user?.id
                  );
                }
              )?.length || ""}</p></div> */}
          </td>
          <td
            onClick={handleCloseDropdown}
            className={`border sticky left-0 h-10 p-1 dark:border-333 ${
              displayMode.value === 'rotation-view'
                ? 'w-[80px] bg-white left-[483px] z-20'
                : 'w-[80px]'
            } dark:bg-138`}
          >
            <p className="text-sm text-center font-bold dark:text-white">
              {shiftSchedules?.filter((shiftSchedule) => {
                return schedule?.user?.id === shiftSchedule?.user?.id;
              })?.length || ''}
            </p>
          </td>
          {dates?.map((date, dateIndex) => (
            <td
              onDoubleClick={() => {
                const selectedTeamMember = teamMembers.find(
                  (x) => x.user.id === schedule?.user?.id
                );
                selectMember(selectedTeamMember);
                selectPTODate(moment(date).format('yyyy-MM-DD'));
                handleOpenPTO(schedule?.user?.id);
              }}
              onClick={() => {
                setInputSearchBranch('');
                const cellClicked = `${moment(date).format('yyyy-MM-DD')}${
                  schedule?.user?.id
                }`;
                handleToggleDropdown(cellClicked);
                handleOpenScheduleOption(cellClicked);
              }}
              className={`border relative h-10 p-1 dark:border-333 ${
                displayMode.value === 'rotation-view' ? '' : ''
              } ${
                [0, 6].includes(date.getDay()) ? 'bg-calendar-weekend' : ''
              } ${
                displayMode.value === 'rotation-view'
                  ? 'w-[200px]'
                  : 'w-[100px]'
              } dark:bg-138`}
            >
              {handleCheckPTOStatus(schedule?.user?.id, date) ? (
                <div className="flex items-center justify-center cursor-not-allowed bg-ccc text-lg text-white h-full dark:bg-444">
                  <p>PTO</p>
                </div>
              ) : (
                <div
                  draggable
                  onDragStart={(e) => onDragStartReassignment(e, date, schedule)}
                  onDragOver={(e) => onDragOver(e, date)}
                  onDrop={(e) => onDrop(e, date, schedule?.user)}
                  className={` h-full rounded flex items-center justify-center`}
                  style={{
                    backgroundColor: getBackgroundColor(schedule, date),
                    color: tinycolor(getBackgroundColor(schedule, date)).isDark() ? "white": "black"
                  }}
                >
                  {shiftSchedules?.length > 0 &&
                  shiftSchedules?.filter((shiftSchedule) => {
                    return (
                      moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                        moment(date).format('yyyy-MM-DD') &&
                      schedule?.user?.id === shiftSchedule?.user?.id
                    );
                  })?.length > 0 ? (
                    <div
                      className="flex gap-2 items-center flex-col relative"
                      onMouseEnter={(event) => {
                        const [shiftScheduleFilter] = shiftSchedules?.filter(
                          (shiftSchedule) => {
                            return (
                              moment(shiftSchedule?.start).format(
                                'yyyy-MM-DD'
                              ) === moment(date).format('yyyy-MM-DD') &&
                              schedule?.user?.id === shiftSchedule?.user?.id
                            );
                          }
                        );
                        let timeoutId = setTimeout(() => {
                          handleOpenPopperShiftDetails(
                            event,
                            shiftScheduleFilter
                          );
                        }, 500);
                        event.currentTarget.addEventListener(
                          'mouseleave',
                          () => {
                            clearTimeout(timeoutId);
                          }
                        );
                      }}
                      onMouseLeave={() => setOpenPopperShiftDetails(null)}
                    >
                      <p className="text-[13px] text-center font-bold">
                        {
                          shiftSchedules?.filter((shiftSchedule) => {
                            return (
                              moment(shiftSchedule?.start).format(
                                'yyyy-MM-DD'
                              ) === moment(date).format('yyyy-MM-DD') &&
                              schedule?.user?.id === shiftSchedule?.user?.id
                            );
                          })[0]?.slot?.branch?.name
                        }
                      </p>
                      <p className="text-[9px] text-left font-bold">
                        {moment(
                          shiftSchedules?.filter((shiftSchedule) => {
                            return (
                              moment(shiftSchedule?.start).format(
                                'yyyy-MM-DD'
                              ) === moment(date).format('yyyy-MM-DD') &&
                              schedule?.user?.id === shiftSchedule?.user?.id
                            );
                          })[0]?.start
                        ).format('h:mma')}
                        -
                        {moment(
                          shiftSchedules?.filter((shiftSchedule) => {
                            return (
                              moment(shiftSchedule?.start).format(
                                'yyyy-MM-DD'
                              ) === moment(date).format('yyyy-MM-DD') &&
                              schedule?.user?.id === shiftSchedule?.user?.id
                            );
                          })[0]?.end
                        ).format('h:mma')}
                      </p>
                      <div className="absolute top-0 right-[-10px]">
                        {shiftSchedules?.filter((shiftSchedule) => {
                          return (
                            moment(shiftSchedule?.start).format(
                              'yyyy-MM-DD'
                            ) === moment(date).format('yyyy-MM-DD') &&
                            schedule?.user?.id === shiftSchedule?.user?.id
                          );
                        })[0].swappable && <img src={swapPic} alt="swap" />}
                      </div>
                    </div>
                  ) : (
                    <p className="dark:text-white">
                      {selectedSchedule ===
                        `${moment(date).format('yyyy-MM-DD')}${
                          schedule?.user?.id
                        }` &&
                      progressState.includes(
                        moment(date).format('yyyy-MM-DD')
                      ) &&
                      // ongoingState.includes(`${schedule?.user?.id}${dateIndex}`)
                      shiftSchedules?.filter((shiftSchedule) => {
                        return (
                          moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                            moment(date).format('yyyy-MM-DD') &&
                          schedule?.user?.id === shiftSchedule?.user?.id
                        );
                      })?.length === 0 &&
                      cellLoading
                        ? 'Loading....'
                        : ''}
                    </p>
                  )}
                  {selectedSchedule ===
                    `${moment(date).format('yyyy-MM-DD')}${
                      schedule?.user?.id
                    }` &&
                    shiftSchedules?.filter((shiftSchedule) => {
                      return (
                        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                          moment(date).format('yyyy-MM-DD') &&
                        schedule?.user?.id === shiftSchedule?.user?.id
                      );
                    })?.length === 0 &&
                    openDropdown &&
                    !openPTO && [1, 2].includes(memberships[0]?.privilege) && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="bg-white dark:bg-292 z-50 top-[80%] max-h-[200px] overflow-y-auto right-0 absolute w-max p-4 shadow-select"
                      >
                        <div className="border-1 flex flex-row gap-1 dark:text-white items-center sticky top-0 bg-white dark:bg-138 py-[10px] px-[5px] rounded-md dark:border-333">
                          <CiSearch />
                          <input
                            className=" focus:outline-none text-black dark:bg-138 "
                            type="text"
                            value={inputSearchBranch}
                            placeholder="search"
                            onChange={(e) =>
                              setInputSearchBranch(e.target.value)
                            }
                          />
                        </div>
                        <div className="min-h-[40px] bg-white">
                          <div className="mt-2 py-2">
                            <p
                              onClick={() => {
                                selectBranch({ id: schedule.branchId });
                                if (
                                  schedule?.user?.secondaryJobroles?.length <
                                    1 ||
                                  schedule?.user?.secondaryJobroles ===
                                    undefined
                                ) {
                                  const data = { id: schedule.branchId };
                                  handleCreateShift(
                                    data,
                                    moment(date).format('yyyy-MM-DD'),
                                    schedule?.user
                                  );
                                } else {
                                  handleSelectedBranch(schedule?.user);
                                }
                              }}
                              className="hover:bg-gray-100 text-black dark:hover:bg-color-333 text-sm py-1 px-[5px] cursor-pointer"
                            >
                              {schedule?.branchName}{' '}
                              <span className="text-[12px] text-gray-400 dark:text-gray-200">
                                {schedule?.city}
                              </span>
                            </p>
                          </div>
                          <hr className="my-2" />
                        </div>
                        {handleSearchBranch(inputSearchBranch).length > 0 ? (
                          handleSearchBranch(inputSearchBranch)?.map(
                            (branch) => (
                              <p
                                onClick={() => {
                                  selectBranch(branch);
                                  if (
                                    schedule?.user?.secondaryJobroles?.length <
                                      1 ||
                                    schedule?.user?.secondaryJobroles ===
                                      undefined
                                  ) {
                                    handleCreateShift(
                                      { ...branch, index: dateIndex },
                                      moment(date).format('yyyy-MM-DD'),
                                      schedule?.user
                                    );
                                  } else {
                                    handleSelectedBranch(schedule?.user);
                                  }
                                }}
                                className="hover:bg-gray-100 text-black dark:hover:bg-color-333 dark:text-white text-sm py-1 px-[5px] cursor-pointer"
                              >
                                {branch?.name}{' '}
                                <span className="text-[12px] text-gray-400 dark:text-gray-200">
                                  {branch?.city?.name}
                                </span>
                              </p>
                            )
                          )
                        ) : (
                          <p className="text-xs text-center mb-3 font-bold">
                            No match
                          </p>
                        )}
                      </div>
                    )}
                  {selectedSchedule ===
                    `${moment(date).format('yyyy-MM-DD')}${
                      schedule?.user?.id
                    }` &&
                    shiftSchedules?.filter((shiftSchedule) => {
                      return (
                        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                          moment(date).format('yyyy-MM-DD') &&
                        schedule?.user?.id === shiftSchedule?.user?.id
                      );
                    })?.length === 0 &&
                    openDropdown &&
                    !openPTO &&
                    jobroleList?.length > 0 && (
                      <div className="absolute bg-white z-50 top-[80%] max-h-[300px] overflow-y-auto left-[100%] w-max shadow-select">
                        {jobroleList?.map((x, index) => (
                          <p
                            onClick={() => {
                              handleCreateShift(
                                { ...branchSelected, index: dateIndex },
                                moment(date).format('yyyy-MM-DD'),
                                schedule?.user,
                                index === 0 ? undefined : x.id
                              );
                            }}
                            className="flex group text-black items-center gap-3 px-4 py-2 cursor-pointer hover:bg-787"
                          >
                            {x?.title}{' '}
                            {index === 0 && (
                              <span className="text-787 text-[10px] font-semibold group-hover:text-white">
                                Primary
                              </span>
                            )}
                          </p>
                        ))}
                      </div>
                    )}
                  {(ongoingState.includes(
                    `${schedule?.user?.id}${dateIndex}`
                  ) ||
                    selectedSchedule ===
                      `${moment(date).format('yyyy-MM-DD')}${
                        schedule?.user?.id
                      }`) &&
                    shiftSchedules?.filter((shiftSchedule) => {
                      return (
                        moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
                          moment(date).format('yyyy-MM-DD') &&
                        schedule?.user?.id === shiftSchedule?.user?.id
                      );
                    })?.length > 0 &&
                    memberships?.length > 0 &&
                    ([1, 2].includes(memberships[0]?.privilege) ||
                      schedule?.user?.id === memberships[0]?.userId) &&
                    openDropdown && (
                      <div className="bg-white z-50 top-[80%] right-0 absolute drop-shadow-lg w-full">
                        {[1, 2].includes(memberships[0]?.privilege) && (<p
                          onClick={() => {
                            const [shiftScheduleFilter] =
                              shiftSchedules?.filter((shiftSchedule) => {
                                return (
                                  moment(shiftSchedule?.start).format(
                                    'yyyy-MM-DD'
                                  ) === moment(date).format('yyyy-MM-DD') &&
                                  schedule?.user?.id === shiftSchedule?.user?.id
                                );
                              });

                            handleCancelSchedule(shiftScheduleFilter.id);
                          }}
                          className="text-xs mb-3 text-171 hover:text-green-600 p-3 cursor-pointer font-bold"
                        >
                          Cancel shift
                        </p>)}
                        <p
                          onClick={() => {
                            const [shiftScheduleFilter] =
                              shiftSchedules?.filter((shiftSchedule) => {
                                return (
                                  moment(shiftSchedule?.start).format(
                                    'yyyy-MM-DD'
                                  ) === moment(date).format('yyyy-MM-DD') &&
                                  schedule?.user?.id === shiftSchedule?.user?.id
                                );
                              });

                            handleEnableShiftSwap(
                              shiftScheduleFilter.id,
                              shiftScheduleFilter.swappable
                            );
                          }}
                          className="text-xs mb-3 hover:text-green-600 p-3 cursor-pointer font-bold"
                        >
                          {shiftSchedules?.filter((shiftSchedule) => {
                            return (
                              moment(shiftSchedule?.start).format(
                                'yyyy-MM-DD'
                              ) === moment(date).format('yyyy-MM-DD') &&
                              schedule?.user?.id === shiftSchedule?.user?.id
                            );
                          })[0].swappable ? (
                            <span className="text-171">Cancel Swap</span>
                          ) : (
                            <span className="text-black hover:text-green-600 dark:text-white">Swap</span>
                          )}
                        </p>
                      </div>
                    )}
                </div>
              )}
            </td>
          ))}
        </tr>
      )
    );
  };

  const toggleAvailableSlot = () => {
    openSlots((prev) => !prev);
  };

  const dropdownSlotClass = 'pt-5 px-2 text-494 text-[13px] font-semibold';

  const onDrag = (event) => {
    openSlots(false);
  };

  const onDragStart = (event, data, index) => {
    const dragData = {
      ...data,
      weekDay: index,
    };

    event.dataTransfer.setData('text', JSON.stringify(dragData));
  };

  const onDragStartReassignment = (event, date, schedule) => {
    const dragData = {
      date: moment(date).format("yyyy-MM-DD").toString(),
      schedule,
    };

    event.dataTransfer.setData('reassignment', JSON.stringify(dragData));
  };

  const onDragOver = (event, date) => {
    event.preventDefault();
  };

  const onDrop = (event, date, user) => {
    event.preventDefault();
    event.stopPropagation();

    

    if(!!event.dataTransfer.getData('text')){
      const dragData = JSON.parse(event.dataTransfer.getData('text'));
    
      openSlots(true);
      if (moment(dragData?.weekDay).format("yyyy-MM-DD") !== moment(date).format("yyyy-MM-DD")) {
        return toast.error('You dragged the shift slot to the wrong weekday');
      }
  
      const cellClicked = `${moment(date).format('yyyy-MM-DD')}${user?.id}`;
  
      handleOpenScheduleOption(cellClicked);
  
      const data = { id: dragData.id };
  
      handleCreateShift(data, moment(date).format('yyyy-MM-DD'), user);
    }

    if(!!event.dataTransfer.getData('reassignment')){

      const dragData = JSON.parse(event.dataTransfer.getData('reassignment'));
      
      if(moment(date).format("yyyy-MM-DD") !== moment(dragData.date).format("yyyy-MM-DD")){
        toast.error(`You can only reassign shift along a specific date`)
        return
      }
     
  
      const cellClicked = `${moment(date).format('yyyy-MM-DD')}${user?.id}`;
  
      handleOpenScheduleOption(cellClicked);

      const shiftRecord = shiftSchedules?.find((shiftSchedule) => {
        return (
          moment(shiftSchedule?.start).format('yyyy-MM-DD') ===
            moment(dragData.date).format('yyyy-MM-DD') &&
            dragData.schedule?.user?.id === shiftSchedule?.user?.id
        );
      })


      if(!shiftRecord){
        return;
      }

      const userJobRole = [
        user?.jobRole.id,
        ...user?.secondaryJobroles.map((x) => x.jobroleId),
      ];

      if(!userJobRole?.includes(shiftRecord?.slotjobRole?.id)){
        toast.error(`user does not have ${shiftRecord?.slotjobRole?.title} has either primary or secondary roles`)
        return
      }

      if(shiftRecord?.id){    
        dispatch(updateLimoScheduleAsync({ id: shiftRecord?.id, data: { userId: user?.id}})).then((res) => {
          if(res.meta.requestStatus === "fulfilled"){
            // toast.success("Shift was successfully reassigned")
            if (['rotation-view', 'name-entry-view'].includes(displayMode.value)) {
              handleOnChangeROT(selectedROT, false);
            } else {
              handleGetShiftSchedule(false);
            }
          } else {
            toast.error(res?.error?.message)
          }
        })
      }

      }
  
  };

  return (
    <section className="dark:bg-138">
      {openGoogle && (
        <GoogleConnectModal
          membershipId={memberships[0]?.id}
          connected={isConnected}
          handleScheduleToOutLook={handleScheduleToOutLook}
          handleLinkToGoogle={handleScheduleToGoogle}
          open={openGoogle}
          loading={linkingLoading}
          handleClose={handleCloseGoogleModal}
        />
      )}
      <AnimatePresence>
        {(openPopper || popperHovered) && (
          <motion.div variants={mainAnim} exit={'exit'}>
            <PopDetail
              openPopper={openPopper}
              setOpenPopper={setOpenPopper}
              handlePopperMouseEnter={handlePopperMouseEnter}
              handlePopperMouseLeave={handlePopperMouseLeave}
              popperHovered={popperHovered}
            >
              <UserDetail userInfo={userInfo} />
            </PopDetail>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {(openPopperShiftDetails || popperHoveredShiftDetails) && (
          <motion.div variants={mainAnim} exit={'exit'}>
            <PopDetail
              openPopper={openPopperShiftDetails}
              setOpenPopper={setOpenPopperShiftDetails}
              handlePopperMouseEnter={handlePopperMouseEnterShiftDetails}
              position="left-start"
              handlePopperMouseLeave={
                isLoadingClocking
                  ? () => {}
                  : handlePopperMouseLeaveShiftDetails
              }
              popperHovered={popperHoveredShiftDetails}
            >
              <ShiftViewDetail
                memberships={memberships}
                isLoadingClocking={isLoadingClocking}
                data={shiftInfo}
                callBack={() => handleOpenMakeOfferViewModal(saveTarget)}
                loggedInUserDetails={loggedInUserDetails.id}
                handleClockInOut={handleClockInOut}
              />
            </PopDetail>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {openMakeOfferViewModal && (
          <MakeOfferView
            openMakeOfferViewModal={openMakeOfferViewModal}
            handleCloseMakeOffer={handleCloseMakeOfferViewModal}
            loggedInUserDetails={loggedInUserDetails.id}
            data={shiftInfo}
          />
        )}
      </AnimatePresence>
      <AppLayout
        custom="shift"
        subCustom="schedules"
        title="Schedules"
        customTitle=""
        layoutClass="dark:bg-138"
        subtitle="View Schedules For Users"
        layoutProps={{
          headerClass: 'dark:text-white',
          subHeaderClass: 'dark:text-969',
        }}
        RightComponent={<TutorialPanel items={scheduleRoles} />}
      >
        {pageIndex === 0 && (
          <div className="">
            <div className="flex items-center mt-16 mb-8 gap-3 sticky top-[4.3rem] z-[100] bg-white">
              <div className="flex flex-wrap flex-row items-center gap-2 w-full dark:bg-138">
                <div className="text-[12px] w-[30%] border-1 px-2 py-3 rounded-md flex gap-2 items-center border-[#c2c2c2] dark:border-333">
                  <div>
                    <BsSearch />
                  </div>

                  <input
                    className="focus:outline-none w-full text-black dark:border-333 dark:bg-138"
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    placeholder="Filter team members"
                  />
                </div>

                {[1,2].includes(privilege) && (<MultipleSelectDropdown
                  data={branches?.map((branch) => ({
                    label: branch?.name,
                    value: branch.id,
                    hexcode: branch?.hexcode,
                  }))}
                  placeholder="All Assigned locations"
                  className="w-48 h-[43px] dark:border-333"
                  value={selectedMultiBranch}
                  dropdownTitleClass="text-A5A"
                  labelText="locations selected"
                  optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
                  setMultiBranch={handleMultiSelect}
                />)}
                {[1,2].includes(privilege) &&(<CustomDropdown
                  data={[
                    { name: 'All Shift locations', value: '' },
                    ...branches,
                  ]?.map((branch) => ({
                    label: branch?.name,
                    value: branch.id,
                    hexcode: branch?.hexcode,
                  }))}
                  className="w-40 h-[43px] dark:border-333"
                  value={selectedShiftBranch}
                  dropdownTitleClass="text-A5A"
                  optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
                  onChangeOption={handleSelectShiftBranch}
                />)}

                {[1,2].includes(privilege) && (<CustomDropdown
                  data={[
                    { name: 'All role tags', value: '' },
                    ...roleTags,
                  ]?.map((roleTag) => ({
                    label: roleTag?.name,
                    value: roleTag.id,
                  }))}
                  className="w-36 h-[43px] dark:border-333"
                  value={selectedRoleTag}
                  dropdownTitleClass="text-A5A"
                  optionClass="z-[1000] shadow-md min-h-[50px]"
                  onChangeOption={handleSelectRoleTag}
                />)}
                {mode === 'calendar' && [1,2].includes(privilege) && (
                  <CustomDropdown
                    data={[
                      { title: 'All job roles', value: '' },
                      ...jobRoles,
                    ]?.map((job) => ({ label: job?.title, value: job.id }))}
                    className="min-w-[150px] w-max h-[43px] dark:border-333"
                    value={selectedJobRole}
                    dropdownTitleClass="text-A5A"
                    optionClass="z-[1000] shadow-md min-h-[50px] w-[max-content]"
                    onChangeOption={handleJobRole}
                  />
                )}
                {mode === 'calendar' && [1,2].includes(privilege) &&(
                  <CustomDropdown
                    data={[
                      { templateName: 'Default Shift Template', id: '' },
                      ...(shiftTemplates?.length > 0 ? shiftTemplates : []),
                    ]?.map((shift) => ({
                      label: shift?.templateName,
                      value: shift?.id,
                      smallLabel: shift?.jobRole?.title,
                      jobId: shift?.jobRole?.id,
                      locations: shift?.locations,
                      daysOfWeek: shift?.daysOfWeek,
                    }))}
                    className=" min-w-[200px] w-max rounded h-[43px] dark:border-333"
                    smallLabelClassName="text-gray-400 group-hover:text-white"
                    value={selectedTemplate}
                    dropdownTitleClass="text-A5A"
                    optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
                    onChangeOption={handleSelectedShiftTemplate}
                  />
                )}
                {mode === 'calendar' && displayMode.value === 'date-picker' && (
                  <CustomNavigateCalender
                    layoutProps={{
                      headerClass: 'dark:text-white',
                      subHeaderClass: 'dark:text-A5A',
                    }}
                    dates={dates}
                    sx={{ fontWeight: '700', fontSize: '13px' }}
                    diff={7}
                  />
                )}
                {/* {mode === 'day' && <NavigateTime timeDiff={23} />} */}
                {displayMode.value === 'day-view' && (
                  <input
                    defaultValue={scheduleDate}
                    type="date"
                    className="border border-9FD h-10 rounded px-2 text-black dark:border-333 dark:bg-138 dark:text-white"
                    onChange={handleDateSelection}
                  />
                )}

                <CustomDropdown
                  data={[
                    'Date picker',
                    'Rotation view',
                    'Stack view',
                    'Day view',
                    'Name Entry View',
                  ].map((mode) => ({
                    label: mode,
                    value: mode.toLowerCase().split(' ').join('-'),
                  }))}
                  className="rounded w-[180px] h-[43px] dark:border-333"
                  value={displayMode}
                  dropdownTitleClass="text-A5A"
                  optionClass="z-[1000]"
                  onChangeOption={handleViewDisplay}
                />

                {['stack-view'].includes(displayMode.value) && (
                  <CustomDropdown
                    data={months}
                    className="w-28 rounded h-[43px] dark:border-333"
                    value={monthSelected}
                    dropdownTitleClass="text-A5A"
                    optionClass="z-[1000]"
                    onChangeOption={handleOnMonthChange}
                  />
                )}

                {mode === 'calendar' &&
                   ['rotation-view', 'name-entry-view'].includes(displayMode.value) && (
                    <CustomDropdown
                      data={Array.from(Array(rotationNumber || 1).keys())?.map(
                        (x) => ({ value: x + 1, label: `ROT ${x + 1}` })
                      )}
                      className="w-28 rounded h-[43px] dark:border-333"
                      value={selectedROT}
                      dropdownTitleClass="text-A5A"
                      optionClass="z-[1000]"
                      onChangeOption={handleOnChangeROT}
                    />
                  )}

                {/* {['name-entry-view'].includes(displayMode.value) && (
                  <CustomDropdown
                    data={months}
                    className="w-28 rounded h-[43px] dark:border-333"
                    value={nameEntrymonthSelected}
                    dropdownTitleClass="text-A5A"
                    optionClass="z-[1000]"
                    onChangeOption={handleOnChangeNamEntryMonth}
                  />
                )} */}

                {mode === 'calendar' && (
                  <CustomDropdown
                    data={getDatesArray(
                      dateCreated,
                      new Date().toString()
                    )?.map((dates) => ({ label: dates, value: dates }))}
                    className="w-28 rounded h-[43px] dark:border-333"
                    value={yearSelected}
                    dropdownTitleClass="text-A5A"
                    optionClass="z-[1000]"
                    onChangeOption={handleOnDateChange}
                  />
                )}
              </div>

              <div className=" item gap-3 hidden">
                {/* <div
              onClick={() => handleModeSelect('calendar')}
              className="flex items-center gap-3 cursor-pointer"
            >
              <p
                className={`font-medium text-sm ${
                  mode === 'calendar' ? 'text-5F3' : ''
                }`}
              >
                <BsCalendarMonth />
              </p>
              <p
                className={`font-medium text-sm ${
                  mode === 'calendar' ? 'text-5F3' : ''
                }`}
              >
                Calendar
              </p>
            </div> */}
                {/* <div
              onClick={() => handleModeSelect('day')}
              className="flex items-center gap-3 cursor-pointer"
            >
              <p className={mode === 'day' ? 'text-5F3' : ''}>
                <BsCalendarDay />
              </p>
              <p
                className={`font-medium text-sm w-max ${
                  mode === 'day' ? 'text-5F3' : ''
                }`}
              >
                Day View
              </p>
            </div> */}
              </div>
            </div>

            {!['rotation-view', 'name-entry-view'].includes(displayMode.value) && (<div className={`flex justify-between items-center p-2 dark:bg-138 ${displayMode.value === 'rotation-view' ? "relative" : "sticky top-16 bg-white z-[50]"}`}>
              {!!selectedTemplate?.value ? (
                <p
                  onClick={toggleAvailableSlot}
                  className="text-558 text-sm font-bold cursor-pointer"
                >
                  {isOpenSlotOpen ? 'Close Open shifts' : 'Open Shifts'}
                </p>
              ) : (
                <div />
              )}
              {isOpenSlotOpen && (
                <ClickAwayListener
                  onClickAway={() => {
                    openSlots(false);
                  }}
                >
                  <div className={`absolute right-0  top-0 bg-white rounded-md min-w-[74%] ${ dates?.length > 8 ? "max-w-[79%]" : "max-w-[95%]"} overflow-y-scroll shadow-shift-shadow z-50 px-5`}>
                    <table className={`${ dates?.length > 8 ? "w-[2400px]" : ""}`}>
                      <thead>
                      <tr>
                      {
                      dates?.map((dat) => (
                        <th>
                      <div className={`w-[100px] ${[0,6].includes(moment(dat).day()) ? "bg-calendar-weekend":""}  ${dropdownSlotClass}`}>
                        <p>{moment(dat).format("ddd DD MMM")}</p>
                      </div>
                      </th>
                      ))
                    }
                      </tr>
                      </thead>
                      <tr>
                      {dates
                      ?.map((dat, index) => {
                        const data = [];

                        selectedTemplate?.locations?.forEach((loc) => {
                          const numberOfSchedule = shiftSchedules?.filter(
                            (x) =>
                              x?.branchId === loc.location.id &&
                              moment(x?.start).format('yyyy-MM-DD') === moment(dat).format('yyyy-MM-DD')
                          ).length;
                          const remainingSlots =
                            (loc?.staffRequired || 0) - numberOfSchedule;
                          for (let b = 0; b < remainingSlots; b++) {
                            data.push(loc?.location);
                          }
                        });

                        const daysOfWeek = selectedTemplate?.daysOfWeek?.split(",")?.map((y) => Number(y));

                        return (
                          <td>
                          <div
                            id={daysOfWeek[moment(dat).day()]}
                            className={`flex flex-col pl-2 px-2 ${
                              [0, 6].includes(daysOfWeek[moment(dat).day()])
                                ? 'bg-calendar-weekend'
                                : ''
                            }`}
                          >
                            {daysOfWeek[moment(dat).day()] ? (
                              data?.filter((bran) => {
                                if(selectedMultiBranch?.length > 0){
                                  return selectedMultiBranch?.map((ele) => ele?.value).includes(bran?.id)
                                }
                                return true
                              })?.map((y) => (
                                <div
                                  onDrag={onDrag}
                                  draggable="true"
                                  onDragStart={(e) => onDragStart(e, y, moment(dat).format("yyyy-MM-DD"))}
                                  className={`h-[30px] mb-3 cursor-pointer rounded-md flex items-center justify-center text-white font-bold px-2`}
                                  style={{ backgroundColor: y.hexcode }}
                                >
                                  <p className="w-[78px] whitespace-nowrap text-center overflow-hidden text-ellipsis">
                                    {y?.name}
                                  </p>
                                </div>
                              ))
                            ) : (
                              <div />
                            )}
                          </div>
                          </td>
                        );
                      })}
                      </tr>
                    </table>
                    
                  </div>
                </ClickAwayListener>
              )}
              <button
                onClick={() => setGoogleModal(true)}
                className="p-2 rounded flex items-center gap-3"
              >
                <img src={SyncIcon} alt="img" height="30px" width="30px" />
                <p className="font-bold dark:text-white">Sync</p>
              </button>
            </div>)}

            <ClickAwayListener
              onClickAway={() => {
                selectSchedule('');
                setOpenDropdown(false);
                setNameEntryInput('')
                setIsHover(false)
              }}
            >
              <div>
              <div className={`sticky dark:bg-138`}>
                {(cellLoading || viewChanging || limboLoading) && (
                  <LinearProgress className="mb-1" />
                )}

                {[
                  'date-picker',
                  'name-entry-view',
                  'name-entry-view',
                  'stack-view',
                ].includes(displayMode.value) && (
                  <section className="shadow-shift-shadow rounded-sm bg-white dark:bg-138">
                    {mode === 'calendar' &&
                      ![
                        'stack-view',
                        'day-view',
                        'name-entry-view',
                        'rotation-view',
                      ].includes(displayMode.value) && (
                        <table
                          className={`${
                            displayMode.value === 'date-picker'
                              ? ''
                              : 'w-[3700px] absolute border-collapse'
                          }`}
                        >
                          <thead className="bg-white dark:bg-138 sticky top-32 z-50">
                          <tr>
                            <td className={`w-[175px] border-r-0  dark:bg-138 dark:border-333  bg-white`}></td>
                            <td className={`w-[20px] border-r-0 dark:bg-138 dark:border-333 bg-white`}></td>
                            {
                              [...new Set(dates.map((x) => moment(x).format('MMM')))]?.map((y, index) => (<td className="dark:bg-138 bg-white" colSpan={dates.map((dat) => moment(dat).format('MMM')).filter((z) => z === y).length} ><p className="dark:text-white font-extrabold text-[20px] text-[#444]">{y}</p></td>))
                            }
                            </tr>
                            {!!selectedTemplate?.value && (
                              <tr>
                                <td></td>
                                <td></td>
                                {dates?.map((value, index) => {
                                  if (
                                    index > 6 &&
                                    displayMode.value === 'date-picker'
                                  )
                                    return null;
                                  return (
                                    <td>
                                      <p className="font-extrabold font text-base text-39E flex items-center gap-3">
                                        <div className="flex items-center gap-1">
                                          <span>{`${handleTotalSlot()}`} </span>
                                          <span
                                            className={
                                              calFirstTrack(value) < 0
                                                ? 'text-red-700'
                                                : ''
                                            }
                                          >
                                            {calFirstTrack(value)}
                                          </span>
                                        </div>{' '}
                                        <div className="flex items-center gap-1">
                                          {' '}
                                          <span>{`${handleRelevantSlot()}`}</span>{' '}
                                          <span
                                            className={
                                              calSecondTrack(value) < 0
                                                ? 'text-red-700'
                                                : ''
                                            }
                                          >
                                            {calSecondTrack(value)}
                                          </span>
                                        </div>
                                      </p>
                                    </td>
                                  );
                                })}
                              </tr>
                            )}
                            <tr className="border">
                              <td
                                className={`w-[175px] border-b border-r z-50 dark:bg-138 dark:border-333 sticky`}
                              >
                                <p className="px-3 w-[170px] text-sm dark:text-white">
                                  Employee Name
                                </p>
                              </td>
                              <td
                                className={`w-[20px] border-b border-r z-50 dark:bg-138 dark:border-333 sticky ${
                                  displayMode.value === 'date-picker'
                                    ? ''
                                    : 'left-0 bg-white'
                                }`}
                              >
                                <p className="px-3 text-center dark:text-white text-sm">
                                  Shifts
                                </p>
                              </td>
                              {dates?.map((value, index) => {
                                if (
                                  index > 6 &&
                                  displayMode.value === 'date-picker'
                                )
                                  return null;

                                return (
                                  <td
                                    className={`cursor-pointer border-b dark:border-t border-r dark:border-333 p-4 ${
                                      [0, 6].includes(value.getDay())
                                        ? 'bg-calendar-weekend dark:bg-138'
                                        : ''
                                    } w-[100px] dark:bg-138`}
                                  >
                                    <DateLabel
                                      padding={'10px'}
                                      className=""
                                      detailsWeek={value}
                                      hideMonth
                                      type="week"
                                      layoutProps={{
                                        headerClass: 'dark:text-white text-sm',
                                        subHeaderClass: 'dark:text-969',
                                      }}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                          {(loading || loaderLoading) && (
                            <tr>
                              <td
                                colSpan={
                                  displayMode.value === 'date-picker' ? 8 : 13
                                }
                              >
                                <div className="w-full h-[300px] flex justify-center items-center">
                                  <Loader />
                                </div>
                              </td>
                            </tr>
                          )}
                          {!loading &&
                            !loaderLoading &&
                            (handleSchedule(selectedMultiBranch).filter(
                              (x) => x?.mode === 'data'
                            )?.length === 0 ||
                              jobRoles?.length === 0) && (
                              <tr>
                                <td
                                  colSpan={
                                    displayMode.value === 'date-picker' ? 9 : 28
                                  }
                                >
                                  <div className="w-full h-[300px] flex justify-center items-center dark:text-white">
                                    <p>No Members in this branch</p>
                                  </div>
                                </td>
                              </tr>
                            )}

                          {!loading &&
                            !loaderLoading &&
                            handleSchedule(selectedMultiBranch)?.filter(
                              (x) => x?.user.id === userId
                            )?.length > 0 && (
                              <>
                                {Array.from(handleSchedule(selectedMultiBranch))
                                  ?.filter((x) => x?.user.id === userId)
                                  ?.map((schedule) => (
                                    <>{handleRenderScheduleCell(schedule)}</>
                                  ))}
                              </>
                            )}
                          {!loading &&
                            !loaderLoading && [1,2].includes(privilege) &&
                            handleGenerateTags()?.map((tags, tagIndex) => (
                              <>
                                {Array.from(handleSchedule(selectedMultiBranch))
                                  ?.slice(offset, offset + rowsPerPage)
                                  ?.filter(
                                    (x) =>
                                      handleRoleTags(x?.user?.jobRole?.id)?.map(
                                        (y) => y?.tagInfo?.id
                                      )[0] === tags.id
                                  )?.length > 0 && (
                                  <tr>
                                    <td
                                      onClick={handleCloseDropdown}
                                      className={`border border-l-0 p-4 dark:bg-138 dark:border-333`}
                                      colSpan={8}
                                    >
                                      <p
                                        className="font-bold text-[10px] dark:text-white"
                                        style={{ color: tags?.hexcode }}
                                      >
                                        {tags?.name}
                                      </p>
                                    </td>
                                  </tr>
                                )}
                                {handleCellRender(true, tags)?.map(
                                  (schedule, index) => (
                                    <>
                                      {handleRenderScheduleCell(
                                        schedule,
                                        index ===
                                          handleCellRender(true, tags)?.length -
                                            1 &&
                                          handleGenerateTags()?.length - 1 !==
                                            tagIndex,
                                        [1,2].includes(privilege)
                                      )}
                                    </>
                                  )
                                )}
                              </>
                            ))}
                          <tr>
                            <td
                              colSpan={
                                displayMode.value === 'date-picker' ? 9 : 30
                              }
                            >
                              {[1, 2].includes(memberships[0]?.privilege) && (jobRoles?.length > 0 ||
                                handleSchedule(selectedMultiBranch)?.length >
                                  rowsPerPage) &&
                                !loaderLoading &&
                                !loading &&
                                displayMode.value !== 'day-view' && (
                                  <div className="flex mb-20 justify-end">
                                    <TablePagination
                                      className="dark:text-grey-600"
                                      component="div"
                                      count={
                                        handleSchedule(selectedMultiBranch)
                                          ?.length || 0
                                      }
                                      page={page}
                                      onPageChange={handleChangePage}
                                      rowsPerPage={rowsPerPage}
                                      onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                      }
                                    />
                                  </div>
                                )}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      )}
                    

                    {displayMode.value === 'name-entry-view' && (
                      <NameEntryView
                        selectSchedule={selectSchedule}
                        setOpenDropdown={setOpenDropdown}
                        dates={dates}
                        loading={loading}
                        userID={userId}
                        loaderLoading={loaderLoading}
                        handleSchedule={handleSchedule}
                        selectedMultiBranch={selectedMultiBranch}
                        jobRoles={jobRoles}
                        branches={branches}
                        handleCreateScheduleEntry={handleCreateScheduleEntry}
                        handleOpenPopper={handleOpenPopper}
                        setOpenPopper={setOpenPopper}
                        handleOpenUserProfile={handleOpenUserProfile}
                        setInputSearchBranch={setInputSearchBranch}
                        handleToggleDropdown={handleToggleDropdown}
                        handleOpenScheduleOption={handleOpenScheduleOption}
                        getBackgroundColor={getBackgroundColor}
                        shiftSchedules={shiftSchedules}
                        handleOpenPopperShiftDetails={
                          handleOpenPopperShiftDetails
                        }
                        setOpenPopperShiftDetails={setOpenPopperShiftDetails}
                        selectedSchedule={selectedSchedule}
                        progressState={progressState}
                        cellLoading={cellLoading}
                        openDropdown={openDropdown}
                        handleSearchBranch={handleSearchBranch}
                        inputSearchBranch={inputSearchBranch}
                        selectBranch={selectBranch}
                        handleCreateShift={handleCreateShift}
                        handleSelectedBranch={handleSelectedBranch}
                        jobroleList={jobroleList}
                        branchSelected={branchSelected}
                        memberships={memberships}
                        handleCancelSchedule={handleCancelSchedule}
                        handleEnableShiftSwap={handleEnableShiftSwap}
                        handleGenerateTags={handleGenerateTags}
                        offset={offset}
                        rowsPerPage={rowsPerPage}
                        handleRoleTags={handleRoleTags}
                        searchInput={nameEntryInput}
                        setInput={setNameEntryInput}
                        {
                          ...{
                            isOpenSlotOpen,
                            openSlots,
                            jobroleList,
                            selectedTemplate,
                            toggleAvailableSlot,
                            dropdownSlotClass,
                            onDrag,
                            onDragStart,
                            onDragOver,
                            onDragStartReassignment,
                            page,
                            handleChangePage,
                            handleChangeRowsPerPage,
                            onDrop,
                            selectedSchedule,
                            memberships,
                            handleCancelSchedule,
                            handleEnableShiftSwap,
                            isBranchhover,
                            setIsHover,
                          }
                        }
                      />
                    )}

                    {displayMode.value === 'stack-view' && (
                      <LocationStackView
                        yearSelected={yearSelected}
                        monthSelected={monthSelected}
                        openDropdown={openDropdown}
                        dispatch={dispatch}
                        memberships={memberships}
                        privilege={privilege}
                        calendarDisplay={calendarDisplay}
                        handleSchedule={handleSchedule}
                        handleOpenScheduleOption={handleOpenScheduleOption}
                        getBackgroundColor={getBackgroundColor}
                        shiftSchedules={shiftSchedules}
                        handleSelectedBranch={handleSelectedBranch}
                        selectBranch={selectBranch}
                        setOpenDropdown={setOpenDropdown}
                        handleOpenPopperShiftDetails={
                          handleOpenPopperShiftDetails
                        }
                        selectedMultiBranch={selectedMultiBranch}
                        setOpenPopperShiftDetails={setOpenPopperShiftDetails}
                        selectedSchedule={selectedSchedule}
                        cellLoading={cellLoading}
                        branches={branches}
                        selectMember={selectMember}
                        selectPTODate={selectPTODate}
                        teamMembers={teamMembers}
                        handleOpenPTO={handleOpenPTO}
                        inputSearchBranch={inputSearchBranch}
                        handleToggleDropdown={handleToggleDropdown}
                        setInputSearchBranch={setInputSearchBranch}
                        handleCreateShift={handleCreateShift}
                        handleCancelSchedule={handleCancelSchedule}
                        handleEnableShiftSwap={handleEnableShiftSwap}
                        selectSchedule={selectSchedule}
                        handleOpenUserProfile={handleOpenUserProfile}
                        {...{ onDrag,
                          dates,
                          onDragStart,}}
                      />
                    )}

                    <CreateShiftSlot
                      open={openShiftSlot}
                      shiftSlotData={shiftSlotData}
                      branches={branches}
                      // refresh={refresh}
                      handleClose={handleClose}
                    />
                  </section>
                )}

                  {displayMode.value === 'day-view' && (
                      <ScheduleDayView
                        branchId={selectedShiftBranch?.value}
                        scheduleDate={scheduleDate}
                        searchText={searchText}
                        page={page}
                        displayMode={displayMode}
                        handleChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    )}
                {displayMode.value === 'rotation-view' && (
                  <RotationView
                    {...{
                      displayMode,
                      selectedTemplate,
                      dates,
                      handleTotalSlot,
                      calFirstTrack,
                      handleRelevantSlot,
                      calSecondTrack,
                      loading,
                      loaderLoading,
                      handleSchedule,
                      selectedMultiBranch,
                      jobRoles,
                      handleRenderScheduleCell,
                      userId,
                      handleGenerateTags,
                      offset,
                      rowsPerPage,
                      handleRoleTags,
                      handleCloseDropdown,
                      handleCellRender,
                      page,
                      handleChangePage,
                      handleChangeRowsPerPage,
                      handleOpenPopper,
                      setOpenPopper,
                      handleOpenUserProfile,
                      teamMembers,
                      selectMember,
                      handleOpenPTO,
                      selectPTODate,
                      setInputSearchBranch,
                      handleToggleDropdown,
                      handleOpenScheduleOption,
                      shiftSchedules,
                      handleCheckPTOStatus,
                      onDragOver,
                      onDrop,
                      onDragStartReassignment,
                      getBackgroundColor,
                      handleOpenPopperShiftDetails,
                      setOpenPopperShiftDetails,
                      swapPic,
                      selectedSchedule,
                      progressState,
                      cellLoading,
                      openDropdown,
                      openPTO,
                      inputSearchBranch,
                      selectBranch,
                      handleCreateShift,
                      handleSelectedBranch,
                      handleSearchBranch,
                      jobroleList,
                      branchSelected,
                      ongoingState,
                      memberships,
                      handleCancelSchedule,
                      handleEnableShiftSwap,
                      toggleAvailableSlot,
                      isOpenSlotOpen,
                      openSlots,
                      dropdownSlotClass,
                      onDrag,
                      onDragStart
                    }}
                  />
                )}
              </div>
              </div>
            </ClickAwayListener>
          </div>
        )}

        {pageIndex === 1 && (
          <TeamMemberDetails
            setIndex={setPageIndex}
            selectedUser={selectedUserProfile?.user?.profileInfo}
            branchId={selectedUserProfile?.branchId}
            branchName={'Schedules'}
            showTeamText={false}
          />
        )}

        {openPTO && (
          <RequestTimeOff
            selectedDate={selectedPTODate}
            teamMember={selectedMember}
            openModal={openPTO}
            setOpenModal={setPTOMode}
          />
        )}

        {
          openConfirmShift && (<ConfirmShiftModal open={openConfirmShift} handleClose={handleCloseConfirmInfo} loading={cellLoading} callback={() => handleCreateShift(shiftTempData.current?.e, shiftTempData.current?.date, shiftTempData.current?.user, undefined,shiftTempData.current?.toggle,()=>{})} />)
        }
      </AppLayout>
    </section>
  );
};

const ShiftViewDetail = ({
  data,
  callBack,
  loggedInUserDetails,
  memberships,
  isLoadingClocking,
  handleClockInOut,
}) => {
  return (
    <div className="flex flex-col gap-4 py-4 px-8 z-[30]">
      <div className="flex gap-4 items-center">
        <Container title={'Day'} desc={moment(data?.start).format('dddd')} />
        <Container
          title={'Time'}
          desc={`${data?.slot?.startTime} ${data?.slot?.endTime}`}
        />
        {data?.swappable && (
          <img className="w-4 h-4" src={swapPic} alt="swap" />
        )}
      </div>
      <Container
        title={'Employee name'}
        desc={`${data?.user?.firstName} ${data?.user?.lastName}`}
      />

      <Container
        title={'Job role'}
        desc={data?.slotjobRole?.title}
        hexcode={data?.slotjobRole?.hexcode}
      />

      {data?.fillInRole !== null && (
        <Container
          title={`Filling In As : ${data?.jobrole?.title}`}
          desc={data?.jobrole?.title}
          hexcode={data?.jobrole?.hexcode}
        />
      )}

      {data?.swappable && loggedInUserDetails !== data?.user?.id && (
        <OutlinedButton
          text={'Make Offer'}
          callBack={callBack}
          className="dark:!text-gray-400 dark:!border-969"
        />
      )}
      {[1, 2].includes(memberships[0]?.privilege) && !isLoadingClocking && (
        <div className="w-[100%]">
          {data?.clockedIn === null && (
            <OutlinedButton
              className="dark:!text-gray-400 dark:!border-969"
              width={'100%'}
              text={'Manual Clock In'}
              callBack={() => handleClockInOut('clockIn', data?.id)}
            />
          )}
          {data?.clockedOut === null && data?.clockedIn !== null && (
            <OutlinedButton
              className="dark:!text-gray-400 dark:!border-969"
              width={'100%'}
              text={'Manual Clock Out'}
              callBack={() => handleClockInOut('clockOut', data?.id)}
            />
          )}
        </div>
      )}

      {[1, 2].includes(memberships[0]?.privilege) && isLoadingClocking && (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

const Container = ({ title, desc, hexcode }) => {
  return (
    <div className="flex flex-col">
      <p className="text-[11px] text-gray-400">{title}</p>

      <div className="flex gap-2 items-center">
        <p className="text-[14px]">{desc}</p>
        <p
          className="w-2 h-2 mt-1 rounded-full"
          style={{ background: hexcode }}
        ></p>
      </div>
    </div>
  );
};
