import { Modal } from "@material-ui/core";
import { Stack } from "@mui/material";
import SuccessGif from "../../../../Assets/gif/Success.gif";

export function NewResetPasswordModal({ open = false, handleClose, navigate }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ height: "100vh" }}
        justifyContent="center"
      >
        <div className="px-[20px] flex flex-col rounded-[11px] justify-end py-[50px] gap-y-4 bg-white w-[612px] h-[335px] text-center">
          <img
            src={SuccessGif}
            alt="Success animation"
            className="w-[120px] h-[120px] object-contain mx-auto"
          />
          <p className="text-[28px] font-semibold">
            Password sucessfully reset!
          </p>
          <p className="text-base text-A6A">
            You will be auto-redirected to the login screen to get access to
            your account using your new password. If auto-redirection fails,
            pleace click the button below.
          </p>
          <p
            onClick={() => {
              navigate("/login");
              handleClose();
            }}
            className="text-E42 text-sm font-semibold cursor-pointer underline underline-E42"
          >
            Back to Home
          </p>
        </div>
      </Stack>
    </Modal>
  );
}
