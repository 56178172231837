/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { SideBar } from '../../Widgets/SideBar';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import { useEffect, useState } from 'react'
import { companyRotationDataThunk } from '../../../Store/Company';
import moment from 'moment'
import { getRotationStatusASync, setRotationStatusASync } from '../../../Store/Rotation';
import { ClickAwayListener } from '@material-ui/core';
import { Loader } from '../../Widgets/Loader';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { rotationManagementTutorial } from '../mock';
import { NewSideBar } from '../../Widgets/NewSideBar';
import { IoIosMore } from "react-icons/io"
import ViewCopyRotation from '../ShiftScreen/Tabs/Rotations/Widgets/CopyRotation/viewCopyRotation';
import { getDatesArray } from '../../../utils/dateArray';

function RotationManagement(){
  const dispatch = useDispatch()
  const [dropdownState, openDropdown] = useState('')
  const [copyRotationdropdownState, openCopyRotationDropdown] = useState('')
  const rotationData = useSelector(state => state.company.rotationData)
  const rotationNumber = useSelector(state => state.company.activeCompany.rotationDays)
  const rotationStart = useSelector(state => state.company.activeCompany.rotationStart)
  const loading = useSelector(state => state.company.loading)
  const stateStore = useSelector((state) => state.company);
  const store = useSelector((state) => state.rotations);
  const dateCreated = useSelector(
    (state) => state.company.activeCompany.createdOn
  );

  const [selectedRotation, selectRotation] = useState(1)
  const [openViewCopyRotation, setOpenViewCopyRotation] = useState(false);
  const [selectedYear, selectYear] = useState(moment().format("yyyy"))
  const [selectedCopyRotation, selectCopiedRotation] = useState({})
  
  useEffect(()=>{
    dispatch(companyRotationDataThunk())
  },[dispatch])

  useEffect(()=>{
    handleCallback()
  },[dispatch, selectedRotation, selectedYear])


  const handleCallback = () => {
    dispatch(
      getRotationStatusASync({
        rotationNumber: selectedRotation,
        rotationYear: Number(selectedYear),
      })
    );
    dispatch(companyRotationDataThunk())
  }

  const handleRotationStatus = (status, year, rotationNumber) => {
    const data = {
      rotationYear: Number(year),
      rotationNumber: rotationNumber,
      rotationStatus: status === 0 ? 1 : 0,
    };
    dispatch(setRotationStatusASync(data)).then(() => {
      dispatch(companyRotationDataThunk())
      openDropdown('')
    });
  };

  const handleSelectCell = (key) => {
    openDropdown(key)
  }

  const handleCopyRotaSelectCell = (key) => {
    openCopyRotationDropdown(key)
  } 

  const handleSaveRotationAndDate = (rot,year) => {
    selectRotation(rot)
    selectYear(year)
  }

  const handleCloseViewCopyRotation = () => {
    setOpenViewCopyRotation(false);
  };

  const handleOpenViewCopyRotation = () => {
    setOpenViewCopyRotation(true);
  };


  return (
    <NewSideBar custom="company" subCustom="rotation-management" customTitle=" ">
      <div className="flex items-center justify-between">
      <BranchesHeader title="Rotation Management" subtitle="Manage Rotations" />
      <TutorialPanel items={rotationManagementTutorial} />
      </div>
      <div className="my-[62px] flex items-end gap-5">
        <div className="text-center">
          <p className="text-[40px] font-bold text-E7E">{rotationNumber || 0}</p>
          <p className="text-777 text-lg font-bold">Rotation Days</p>
        </div>
        <div className="text-center ml-[133px]">
          <p className="text-[28px] font-bold text-E7E">{moment(rotationStart).format("Do MMM yyyy")}</p>
          <p className="text-777 text-lg font-bold">Start Of Rotation Year</p>
        </div>
      </div>

      <table className="text-center">
        <thead className="bg-0F5 dark:bg-1F1 text-center">
          <th className="py-[25px] px-[19px] dark:text-white">Rotation #</th>
          <th className="py-[25px] px-[19px]  dark:text-white text-left">Start Date</th>
          <th className="py-[25px] px-[19px] dark:text-white text-left">End Date</th>
          <th className="py-[25px] px-[19px] dark:text-white">No of Shift</th>
          <th className="py-[25px] px-[19px] dark:text-white">Private / Public</th>
          <th className="py-[25px] px-[19px] dark:text-white"></th>
        </thead>
        <tbody>
          {
            loading && <tr>
              <td className="h-[300px]" colSpan={5}><Loader /></td>
            </tr>
          }
          {
            !loading && [...Array.isArray(rotationData) ? rotationData : Object.values(rotationData)]?.map((x, index) => (
              <tr>
                <td className="py-[25px] px-[19px] dark:text-white text-center">{index + 1}</td>
                <td className="py-[25px] px-[19px] dark:text-white text-left">{moment(x?.start).format("Do MMM yyyy")}</td>
                <td className="py-[25px] px-[19px] dark:text-white text-left">{moment(x?.end).format("Do MMM yyyy")}</td>
                <td className="py-[25px] px-[19px] dark:text-white">{x?.counts}</td>
                <td className="py-[25px] relative px-[19px] flex justify-center">
                 {x.published === 0 && ( <p onClick={()=> handleSelectCell(index)} className="font-medium text-839 bg-6EE p-[10px] px-10 cursor-pointer rounded-md w-fit">Private</p>)}
                 {x.published === 1 && ( <p onClick={()=> handleSelectCell(index)} className="font-medium text-D57 bg-CFA p-[10px] px-10 cursor-pointer rounded-md w-fit">Public</p>)}
                 {dropdownState === index && (
                   
                   <ClickAwayListener
                   onClickAway={() => {
                    openDropdown('')
                   }}
                   >
                 <div className="absolute right-0 w-[200px] z-50 bg-white shadow-custom">
                  <p onClick={() => handleRotationStatus(x.published, moment(x?.start).format("yyyy"), index+1)} className="font-medium hover:bg-767 my-2 m-[15px] text-839 bg-6EE p-[10px] px-10 cursor-pointer rounded-md w-fit">Private</p>
                  <p onClick={() => handleRotationStatus(x.published, moment(x?.start).format("yyyy"), index+1)} className="font-medium hover:bg-767 m-[15px] text-D57 bg-CFA p-[10px] px-10 cursor-pointer rounded-md w-fit">Public</p>
                 </div>
                 </ClickAwayListener>
                 )}
                  </td>
                  <td>
                    <IoIosMore onClick={()=> handleCopyRotaSelectCell(index)}  className="cursor-pointer" />
                    
                      {copyRotationdropdownState === index && (
                   
                        <ClickAwayListener
                        onClickAway={() => {
                          openCopyRotationDropdown('')
                        }}
                        >
                      <div className="absolute right-0 w-[200px] z-50 bg-white shadow-custom">
                       <p onClick={() => {
                        handleSaveRotationAndDate(index+1,moment().format("yyyy"));
                        handleOpenViewCopyRotation();
                        selectCopiedRotation({from: {
                          year: moment().format("yyyy"),
                          rotationNumber: index + 1
                        }})
                        openCopyRotationDropdown('')
                       }} className="font-medium my-2 hover:bg-483 text-black p-[10px] cursor-pointer rounded-md w-full">Copy Rotation</p>
                      </div>
                      </ClickAwayListener>
                      )}
                    
                    </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      {openViewCopyRotation && (<ViewCopyRotation
      openViewCopyRotation={openViewCopyRotation}
      handleCloseViewCopyRotation={handleCloseViewCopyRotation}
      stateStore={stateStore}
      store={store}
      cb = {handleCallback}
      defaultRotation={selectedCopyRotation}
      year={getDatesArray(dateCreated, new Date().toString())?.map(
        (dates) => ({ label: dates, value: dates })
      )}
      />)}
    </NewSideBar>
  )
}

export default RotationManagement