/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SideBar } from '../../../../Widgets/SideBar';
import { BranchesHeader } from '../../../BranchScreen/BranchesHeader/BranchesHeader';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './Widgets/Filter/filter';
import CopyRotations from './Widgets/CopyRotation/copyRotations';
import DropDownMenu from './Widgets/Filter/dropDown';
import ViewCopyRotation from './Widgets/CopyRotation/viewCopyRotation';
import './Rotation.css';
import {
  getRotationASync,
  getRotationFilterASync,
  getRotationStatusASync,
} from '../../../../../Store/Rotation';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { format } from 'date-fns';
import { transformRotationData } from '../../../../../utils/transformRotation';
import { BsDot } from 'react-icons/bs';
import { ClickAwayListener } from '@material-ui/core';
import { Loader } from '../../../../Widgets/Loader';
import { getDatesArray } from '../../../../../utils/dateArray';
import moment from 'moment';

import { CreateShiftSlot } from '../ShiftSlotPage/Widgets/CreateShift/CreateShiftSlot';
import {
  getBranchesAsync,
  fetchJobRolesAsync,
  getSkillSetAsync,
  getTeammemberAsync,
} from '../../../../../Store/Branches';
import ViewShiftSlot from '../ShiftSlotPage/Widgets/CreateShift/ViewShift';
import CustomButton from '../../../../Widgets/CustomButton';
import BackupRotation from './Widgets/BackupRotation/BackupRotation';
import RestoreBackupRotation from './Widgets/RestoreBackup/RestoreBackup';
import { setRotationStatusASync } from '../../../../../Store/Rotation';
import { Switch } from '@mui/material';
import { getShiftTemplateAsync } from '../../../../../Store/Shift';
import { convertTime } from '../../../../../utils/formatTime';
import AppLayout from '../../../../Widgets/AppLayout';

export const Rotations = () => {
  const store = useSelector((state) => state.rotations);
  const shiftStore = useSelector((state) => state.shift)?.shiftTemplates;
  const stateStore = useSelector((state) => state.company);
  const branches = useSelector((state) => state.branches)?.branches?.data;
  const jobRolesData = useSelector((state) => state.branches)?.jobRoles;
  const teamMembers = useSelector((state) => state?.branches)?.teamMembers;
  const filteredItem = useSelector((state) => state.rotations)?.filterItems;
  const rotationStatus = useSelector(
    (state) => state.rotations
  )?.rotationStatus;
  const dateCreated = useSelector(
    (state) => state.company.activeCompany.createdOn
  );
  const dispatch = useDispatch();
  const [yearSelected, setYearSelected] = useState({
    label: moment().format("yyyy"),
    value: moment().format("yyyy"),
  });
  const [selectedUser, selectUser] = useState({
    label: 'All',
    value: '',
  });

  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState('');
  const [shiftSlotData, setShiftSlotData] = useState({});
  const [openBackup, setBackup] = useState(false);
  const [openMenuDropdown, setMenuDropdown] = useState(false);
  const [showDropdown, setDropdown] = useState(null);
  const [openRestore, setRestore] = useState(false);
  const [viewShiftSlotData, setViewShiftSlotData] = useState({});
  const [brandIds, setBranchId] = useState('');
  const [activeTab, setActiveTab] = useState({ label: 'ROT1', value: 1 });
  const [openDropdownChildren, setOpenDropdownChildren] = useState({});
  const [showBranchDetails, setOpenBranchDetails] = useState({});
  const [selectedData, setData] = useState({});

  useEffect(() => {
    refresh();
    getStatus();
  }, [activeTab.value, brandIds, userId, yearSelected.value]);

  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(fetchJobRolesAsync());
    dispatch(getShiftTemplateAsync());
    dispatch(getSkillSetAsync());
    dispatch(getTeammemberAsync({}));
  }, []);

  const getStatus = () => {
    dispatch(
      getRotationStatusASync({
        rotationNumber: activeTab.value,
        rotationYear: Number(yearSelected.value),
      })
    );
  };

  const refresh = () => {
    dispatch(
      getRotationASync({
        year: yearSelected.value,
        branchIds: brandIds,
        rotationNumber: activeTab.value,
        userIds: userId,
      })
    );
  };

  const [openMenu, setOpenMenu] = React.useState(null);
  const [openShiftSlot, setOpenShiftSlot] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [isFiltered, setFiltered] = React.useState('');
  const [openViewShiftSlot, setOpenViewShiftSlot] = React.useState(false);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleApplyFilter = ({ ruleType, ruleValue }) => {
    setFiltered(ruleValue);
    dispatch(
      getRotationFilterASync({
        year: yearSelected.value,
        rotationNumber: activeTab.value,
        ruleType: ruleType,
        ruleValue: ruleValue,
      })
    );
  };

  const [openViewCopyRotation, setOpenViewCopyRotation] = useState(false);
  const handleCloseViewCopyRotation = () => {
    setOpenViewCopyRotation(false);
  };

  const handleOpenShiftTemp = (e) => {
    e.stopPropagation();
    setDropdown(true);
  };

  const handleOnDateChange = (data) => {
    setYearSelected(data);
  };

  const handleOnUserSelected = (data) => {
    selectUser(data);
  };

  const handleTranformRotation = () => {
    const transformedData = transformRotationData(
      store.rotations,
      branches,
      jobRolesData?.filter(
        (job) => job.companyId === stateStore?.activeCompany?.id
      )
    );

    return transformedData;
  };

  const handleHideTableChildren = (id) => {
    setOpenDropdownChildren((prev) => ({ [id]: !prev[id] }));
  };

  const handleShowHideBranchDetails = (id, value) => {
    setOpenBranchDetails((prev) => ({ ...prev, [id]: value }));
  };

  const handleSelectRot = (rotItem) => {
    setActiveTab(rotItem);
  };

  const handleSetBranchId = (id) => {
    setBranchId(id);
  };

  const handleOnClickBranchCell = (e) => {
    const slots = {};
    const timeSlot = store?.rotations?.filter((x) => x.date === e.date)[0];
    const selectedTime = timeSlot?.shifts?.filter(
      (y) => y.slot.jobRole.id === e.jobId && e.branchId === y?.branch?.id
    );

    selectedTime
      ?.map((x) => ({
        start: moment(x.start).format('HH:mm'),
        end: moment(x.end)?.format('HH:mm'),
      }))
      ?.map((x) => {
        if (!slots[`${x.start}-${x.end}`]) {
          slots[`${x.start}-${x.end}`] = 1;
        } else {
          slots[`${x.start}-${x.end}`] = slots[`${x.start}-${x.end}`] + 1;
        }

        return x;
      });

    const checkIfDataExist =
      timeSlot?.shifts?.filter(
        (y) => y.slot.jobRole.id === e.jobId && e.branchId === y?.branch?.id
      ).length > 0;

    setShiftSlotData({...e, assignee: e.assignees?.map((x) => x.userId)});
    if (!checkIfDataExist) {
      setMenuDropdown(true);
    } else {
      setViewShiftSlotData(slots);
      setOpenViewShiftSlot(true);
    }
  };

  const handleFromScratch = (e) => {
    e.stopPropagation();
    setOpenShiftSlot(true);
    setMenuDropdown(false);
    setDropdown(null);
  };

  const handleFromTemplate = (e, jobId, time, maxStaffRequired) => {
    e.stopPropagation();
    setShiftSlotData((prev) => ({
      ...prev,
      jobId,
      selectedTimeData: time,
      maxStaffRequired,
    }));
    setOpenShiftSlot(true);
    setMenuDropdown(false);
    setDropdown(null);
  };

  const handleClose = () => {
    setOpenShiftSlot(false);
  };

  const handleViewShiftClose = () => {
    setOpenViewShiftSlot(false);
  };

  const selectBackground = (inputData, key = undefined) => {
    if (isFiltered?.length > 0) {
      if (inputData?.length > 2) {
        const checkIfPresent = filteredItem?.some(
          (x) =>
            x?.slot?.id === inputData[0]?.slotId &&
            x.start === inputData[0]?.shiftStartDate &&
            x.end === inputData[0]?.shiftEndDate
        );
        return key ? (checkIfPresent ? 'bg-9F1' : 'bg-DED') : '';
      }
      if (inputData?.length > 0 && inputData?.length <= 2) {
        const checkIfPresent = filteredItem?.some(
          (x) =>
            x?.slot?.id === inputData[0]?.slotId &&
            x.start === inputData[0]?.shiftStartDate &&
            x.end === inputData[0]?.shiftEndDate
        );
        return checkIfPresent ? 'bg-9F1' : 'bg-DED';
      }
    }

    return '';
  };

  const handleOpenBackup = () => {
    setBackup(true);
  };

  const handleOpenRestoreBackup = () => {
    setRestore(true);
  };

  const handleRotationStatus = (status) => {
    const data = {
      rotationYear: Number(yearSelected.value),
      rotationNumber: activeTab.value,
      rotationStatus: status ? 1 : 0,
    };
    dispatch(setRotationStatusASync(data)).then(() => {
      getStatus();
    });
  };

  const isAdmin = [1, 2].includes(
    stateStore?.activeCompany?.memberships[0]?.privilege
  );

  return (
    <section className="homepage">
      {openMenu && (
        <DropDownMenu
          handleOnApplyFilter={handleApplyFilter}
          setCompanyId={handleSetBranchId}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
        />
      )}
      <ViewCopyRotation
        openViewCopyRotation={openViewCopyRotation}
        handleCloseViewCopyRotation={handleCloseViewCopyRotation}
        stateStore={stateStore}
        year={getDatesArray(dateCreated, new Date().toString())?.map(
          (dates) => ({ label: dates, value: dates })
        )}
        store={store}
      />
      <AppLayout
        custom="shift"
        subCustom="rotation"
        title="Rotations"
        customTitle=""
        subtitle="View Schedules For Users"
      >

        <section className="overview">
          <div className="flex items-center justify-between w-full">
            {isAdmin && (
              <div className="flex h-8 items-center w-36 justify-between">
                <p>Private</p>
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="success"
                  checked={rotationStatus?.status}
                  onChange={(e) => handleRotationStatus(e.target.checked)}
                />
                <p>Public</p>
              </div>
            )}
          </div>

          <div className="main-body">
            <div className="before-table mt-[40px]">
              <div className="filter flex items-center gap-3">
                <span onClick={(event) => handleOpenMenu(event)}>
                  <Filter boxStyle={{ minHeight: 45 }} />
                </span>
                {/* <NavigateCalender diff={7} /> */}
                <CustomDropdown
                  data={getDatesArray(dateCreated, new Date().toString())?.map(
                    (dates) => ({ label: dates, value: dates })
                  )}
                  className="min-w-[150px] rounded w-max"
                  value={yearSelected}
                  onChangeOption={handleOnDateChange}
                />
                <CustomDropdown
                  data={[
                    {
                      label: 'All',
                      value: '',
                    },
                    ...teamMembers.map((x) => ({
                      value: x?.user?.id,
                      label: `${x?.user?.firstName} ${x?.user?.lastName}`,
                    })),
                  ]?.map((team) => ({
                    label: team?.label,
                    value: team?.value,
                  }))}
                  optionClass="z-[500]"
                  className="min-w-[150px] w-max rounded"
                  value={selectedUser}
                  onChangeOption={handleOnUserSelected}
                />

                <CustomDropdown
                  data={Array.from(Array(stateStore.rotationNumber).keys()).map(
                    (rotationNumber) => ({
                      label: `ROT${rotationNumber + 1}`,
                      value: rotationNumber + 1,
                    })
                  )}
                  className="w-56 rounded border-black"
                  value={activeTab}
                  onChangeOption={handleSelectRot}
                  
                  dropdownTitleClass="text-black"
                  optionClass="h-[100px] overflow-scroll z-[500]"
                />
              </div>
              <span onClick={() => setOpenViewCopyRotation(true)}>
                <CopyRotations />
              </span>
            </div>

            {(isAdmin ||
              (!isAdmin && store?.rotations?.status !== 'Not Public')) && (
              <section className="mt-[62px] border-t-1 overflow-x-auto pb-10">
                <table className="w-[2500px] border-collapse">
                  <thead className="">
                    <th className="border-1 border-t-0 min-w-[200px] max-w-[200px] sticky w-[200px] left-0 bg-white top-0 mt-[-1px] border-9FD font-bold text-[22px] py-[15px] px-[1.22rem] z-[60]">
                      ROT {activeTab?.value}
                    </th>

                    {Array.isArray(store?.rotations) &&
                      store?.rotations
                        ?.map(({ date }) => ({
                          val: moment(date).format('Do MMM').toUpperCase(),
                          day: moment(new Date(date), 'yyyy-mm-dd').day(),
                        }))
                        ?.map((label) => (
                          <th
                            className={`text-xs min-w-[250px] border-t-0 sticky bg-white mt-[-1px] top-0 font-medium border-1 py-[20px] border-9FD  px-[1.22rem] z-50 ${
                              [0, 6].includes(label.day)
                                ? 'bg-calendar-weekend'
                                : ''
                            }`}
                          >
                            {label.val}
                          </th>
                        ))}
                  </thead>
                  {/* <tbody> */}
                  {handleTranformRotation()?.map((rotationData) => (
                    <>
                      {rotationData?.mode === 'header' && (
                        <tr className="border-1 min-w-[250px] border-9FD">
                          <td className="sticky left-0 bg-7F7 border-1 mt-[-1px] w-[110px] h-[48px] "></td>

                          <td
                            className="border-1 border-5A5 bg-7F7 py-[11px] px-[19px] text-xs font-medium"
                            colSpan={store?.rotations?.length}
                          >
                            {rotationData?.branchName?.toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {rotationData?.mode === 'data' &&
                        showBranchDetails[rotationData.id] && (
                          <tr className="border-1 min-w-[250px] border-9FD">
                            <td className="border-1 sticky left-0 z-50 w-[110px] mt-[-1px] border-0E0 bg-white text-xs pl-[10px] py-[14px]">
                              {rotationData?.name}
                            </td>
                            {store?.rotations
                              .map(({ date }) =>
                                format(
                                  new Date(date),
                                  'yyyy-MM-DD'
                                ).toUpperCase()
                              )
                              .map((datesString) => (
                                <td
                                  onClick={() => {
                                    handleOnClickBranchCell({
                                      date: datesString,
                                      jobId: rotationData?.jobId,
                                      branchId: rotationData?.id,
                                      assignees: rotationData[datesString]
                                    });
                                    setData({
                                      datesString,
                                      jobName: rotationData.name,
                                      branchName: rotationData?.branchName,
                                    });
                                  }}
                                  className={`border-1 relative py-[14px] border-0E0 text-xs pl-4 ${selectBackground(
                                    rotationData[datesString]
                                  )} ${
                                    selectedUser?.value !== '' &&
                                    rotationData[datesString].length > 0
                                      ? rotationData[datesString].filter(
                                          (x) =>
                                            x.userId === selectedUser?.value
                                        )?.length > 0
                                        ? 'bg-9F1'
                                        : ''
                                      : ''
                                  }
                                  ${
                                    [0, 6].includes(
                                      moment(
                                        new Date(datesString),
                                        'yyyy-mm-dd'
                                      ).day()
                                    )
                                      ? 'bg-calendar-weekend'
                                      : 'bg-white'
                                  }`}
                                >
                                  <div
                                    className={`w-[10px] absolute bottom-2 right-2 h-[10px] rounded-full ${selectBackground(
                                      rotationData[datesString],
                                      true
                                    )}`}
                                  />

                                  {rotationData[datesString]?.length > 0 && (
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        setOpenDropdownChildren({})
                                      }
                                    >
                                      <div className="relative w-[80%]">
                                        <button
                                          id={`${rotationData?.jobId}-${datesString}`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleHideTableChildren(
                                              `${rotationData?.jobId}-${datesString}`
                                            );
                                          }}
                                          className="bg-6E6 rounded-[6px] text-A5A p-[4px] w-full cursor-pointer"
                                        >
                                          {rotationData[datesString]?.length}{' '}
                                          Team{' '}
                                          {rotationData[datesString]?.length > 1
                                            ? 'members'
                                            : 'member'}
                                        </button>
                                        {openDropdownChildren[
                                          `${rotationData?.jobId}-${datesString}`
                                        ] && (
                                          <div className="shadow-md absolute w-full z-20 bg-white px-1 shadow-select">
                                            {rotationData[datesString]?.map(
                                              (user) => (
                                                <div className="flex items-center cursor-pointer hover:bg-slate-100">
                                                  <BsDot />
                                                  <p className="py-1 text-[10px] text-grey-800 ml-1">
                                                    {user?.name}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </ClickAwayListener>
                                  )}
                                  {/* {
                                    rotationData[datesString]?.length >0 &&
                                  (<p>{
                                    rotationData[datesString]?.length
                                  } Team {
                                    rotationData[datesString]?.length > 1 ? "members": "member"
                                  }</p>)} */}
                                  {/* {rotationData[datesString]?.length > 2 ? (
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        setOpenDropdownChildren({})
                                      }
                                    >
                                      <div className="relative w-[80%]">
                                        <button
                                          id={`${rotationData?.jobId}-${datesString}`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleHideTableChildren(
                                              `${rotationData?.jobId}-${datesString}`
                                            );
                                          }}
                                          className="bg-6E6 rounded-[6px] text-A5A p-[4px] w-full cursor-pointer"
                                        >
                                          {rotationData[datesString]?.length}{' '}
                                          Team members
                                        </button>
                                        {openDropdownChildren[
                                          `${rotationData?.jobId}-${datesString}`
                                        ] && (
                                          <div className="shadow-md absolute w-full z-20 bg-white px-1">
                                            {rotationData[datesString]?.map(
                                              (user) => (
                                                <div className="flex items-center cursor-pointer hover:bg-slate-100">
                                                  <BsDot />
                                                  <p className="py-1 text-[10px] text-grey-800 ml-1">
                                                    {user?.name}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </ClickAwayListener>
                                  ) : rotationData[datesString]?.length ===
                                    2 ? (
                                    <p>{rotationData[datesString]
                                      ?.map((rot) => rot?.name?.split(' ')[0])
                                      ?.join(' / ')}</p>
                                  ) : rotationData[datesString]?.length > 0 &&
                                    rotationData[datesString][0]?.name ? (
                                    rotationData[datesString][0]?.name
                                  ) : (
                                    ''
                                  )} */}
                                  {shiftSlotData?.jobId ===
                                    rotationData?.jobId &&
                                    shiftSlotData?.date === datesString &&
                                    shiftSlotData?.branchId ===
                                      rotationData.id &&
                                    openMenuDropdown && (
                                      <ClickAwayListener
                                        onClickAway={(e) => {
                                          e.stopPropagation();
                                          setMenuDropdown(false);
                                          setDropdown(null);
                                        }}
                                      >
                                        <div className="bg-white shadow-shift-shadow bottom-[-50px] absolute z-50">
                                          <p
                                            onClick={handleFromScratch}
                                            className="py-2 hover:bg-half-transparent cursor-pointer px-3 font-semibold"
                                          >
                                            Create from scratch
                                          </p>
                                          <div className="relative">
                                            <p
                                              onClick={handleOpenShiftTemp}
                                              className="py-2 cursor-pointer px-3 hover:bg-half-transparent font-semibold"
                                            >
                                              Create from template
                                            </p>

                                            {showDropdown ? (
                                              <div className="bg-white shadow-shift-shadow left-[100%] w-max top-0 absolute z-50">
                                                {shiftStore?.map((store) => (
                                                  <p
                                                    onClick={(e) =>
                                                      handleFromTemplate(
                                                        e,
                                                        store?.jobRole?.id,
                                                        `${store?.startTime}-${store?.endTime}`,
                                                        store?.maxStaffRequired
                                                      )
                                                    }
                                                    className="py-2 cursor-pointer px-3 hover:bg-half-transparent font-semibold"
                                                  >
                                                    {store?.templateName}{' '}
                                                    <span className="text-[10px] text-767">
                                                      {convertTime(
                                                        store?.startTime
                                                      )}{' '}
                                                      -{' '}
                                                      {convertTime(
                                                        store?.endTime
                                                      )}
                                                    </span>
                                                  </p>
                                                ))}
                                              </div>
                                            ) : (
                                              <div />
                                            )}
                                          </div>
                                        </div>
                                      </ClickAwayListener>
                                    )}
                                </td>
                              ))}
                          </tr>
                        )}

                      {rotationData?.mode === 'footer' && (
                        <>
                          <tr className="border-1 min-w-[250px] border-9FD">
                            <td className="sticky left-0 bg-white border-1 mt-[-1px] w-[110px] h-[48px] "></td>
                            <td
                              className=" bg-white py-[11px] px-[19px] text-xs font-medium"
                              colSpan={store?.rotations?.length}
                            >
                              {!showBranchDetails[rotationData.id] && (
                                <p
                                  onClick={() =>
                                    handleShowHideBranchDetails(
                                      rotationData.id,
                                      true
                                    )
                                  }
                                  className="underline cursor-pointer"
                                >
                                  Show more
                                </p>
                              )}
                              {showBranchDetails[rotationData.id] && (
                                <p
                                  onClick={() =>
                                    handleShowHideBranchDetails(
                                      rotationData.id,
                                      false
                                    )
                                  }
                                  className="underline cursor-pointer"
                                >
                                  Show less
                                </p>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ))}
                  {store?.loading && (
                    <tr>
                      <td className="py-14" colSpan={store?.rotations?.length}>
                        <Loader />
                      </td>
                    </tr>
                  )}
                  {/* </tbody> */}
                </table>
              </section>
            )}
            {!isAdmin && store?.rotations?.status === 'Not Public' && (
              <div className="w-full flex h-[300px] items-center justify-center">
                <p className="font-medium text-sm text-A5A">
                  Rotation {activeTab.value} Not Public
                </p>
              </div>
            )}
            <div className="mt-16">
              <div className="flex items-center mt-[60px]">
                <CustomButton
                  onClick={handleOpenBackup}
                  showIcon={false}
                  title="Backup Rotation"
                />
                <CustomButton
                  onClick={handleOpenRestoreBackup}
                  containerClass="ml-7"
                  showIcon={false}
                  title="Restore From Backup"
                />
              </div>
            </div>
          </div>
          <CreateShiftSlot
            open={openShiftSlot}
            shiftSlotData={shiftSlotData}
            branches={branches}
            refresh={refresh}
            edit={isEdit}
            setEdit={setEdit}
            handleClose={handleClose}
          />

          <ViewShiftSlot
            open={openViewShiftSlot}
            handleClose={handleViewShiftClose}
            selectedData={selectedData}
            shiftSlotData={viewShiftSlotData}
            data={shiftSlotData}
            setOpenShiftSlot={setOpenShiftSlot}
            setEdit={setEdit}
            setShiftSlotData={setShiftSlotData}
          />

          <BackupRotation
            open={openBackup}
            selectedYear={yearSelected}
            selectedRotation={activeTab}
            handleClose={() => setBackup(false)}
            year={getDatesArray(dateCreated, new Date().toString())?.map(
              (dates) => ({ label: dates, value: dates })
            )}
            store={store}
          />

          <RestoreBackupRotation
            open={openRestore}
            refresh={refresh}
            selectedYear={yearSelected}
            selectedRotation={activeTab}
            handleClose={() => setRestore(false)}
            year={getDatesArray(dateCreated, new Date().toString())?.map(
              (dates) => ({ label: dates, value: dates })
            )}
            store={stateStore}
          />
        </section>
      </AppLayout>
     
    </section>
  );
};
