import React, { useCallback, useEffect } from 'react';
import { font } from '../../../../../Widgets/Styles/font';
import dayjs from 'dayjs';
import {
  Text,
  Field,
  TextAndField,
  FillButton,
  FieldSelect,
  OutlinedButton,
} from '../../../../../Widgets/FormUtils/FormUtils';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobRolesAsync,
  getBranchesAsync,
} from '../../../../../../Store/Branches';
import {
  createShiftTemplateAsync,
  getShiftTemplateAsync,
  updateShiftTemplateAsync,
} from '../../../../../../Store/Shift';
import TimePickerValue from '../../../../../Widgets/CustomDate';
import { useState } from 'react';
import { toast } from 'react-toastify';
import MultipleSelectDropdown from '../../../../../Widgets/MultiDropdown';
import { weekDaysMock } from '../mock';

const className =
  'dark:border-[#333333] dark:border-solid dark:border dark:text-white';

const darkText = localStorage.getItem('color-theme') === 'dark' ? 'white' : '';

export const CreateShiftTemplate = ({
  open,
  handleClose,
  callBack,
  shiftTemplate = {},
  setShiftTemplate = () => {},
}) => {
  
  const branches =
    useSelector((state) => state?.branches)?.branches?.data || [];
  const [selectedMultiBranch, setMultiBranch] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [weekDays, setWeekDay] = useState(weekDaysMock);
  const [endTime, setEndime] = useState(null);
  const [step, setNextStep] = useState(0);
  const dispatch = useDispatch();
  const store = useSelector((state) => state.branches)?.jobRoles;
  const isLoading = useSelector((state) => state.shift)?.loading;

  const handleNextStep = () => {
    setNextStep((prev) => prev + 1)
  }

  const handlePrevStep = () => {
    setNextStep((prev) => prev - 1)
  }


  const formik = useFormik({
    initialValues: {
      templateName: shiftTemplate?.templateName || '',
      jobRoleId: shiftTemplate?.jobRole?.id || '',
      startTime: shiftTemplate?.startTime || '',
      endTime: shiftTemplate?.endTime || '',
      locations: '',
      maxStaffRequired:
        shiftTemplate?.staffRequired || shiftTemplate?.maxStaffRequired || 1,
    },
    enableReinitialize: shiftTemplate?.id?.length > 0,
    validationSchema: Yup.object({
      templateName: Yup.string().required('A job role is required'),
      jobRoleId: Yup.string().required('A job role is required'),
      startTime: Yup.string().required('Start time is required'),
      endTime: Yup.string().required('End time is required'),
      maxStaffRequired: Yup.string().required('Staff is required'),
      locations: Yup.array().required('Locations is required'),
    }),
    onSubmit: (value, actions) => {
      setTimeout(() => {
        if(step === 2) {
          value['maxStaffRequired'] = Number(value['maxStaffRequired']);
          value['startTime'] =
            shiftTemplate?.startTime || dayjs(value['startTime']).format('HH:mm');
          value['endTime'] =
            shiftTemplate?.endTime || dayjs(value['endTime']).format('HH:mm');
            value['locations'] = selectedMultiBranch?.map((x) => ({locationId: x?.value, staffRequired: Number(x?.staffRequired)}))
            value['daysOfWeek'] = weekDays?.map((x) => x.value).join(",")
        if (shiftTemplate?.id) {
          dispatch(
            updateShiftTemplateAsync({
              data: value,
              shiftTempId: shiftTemplate?.id,
            })
          ).then((res) => {
            if (res?.meta?.requestStatus === 'fulfilled') {
              dispatch(getShiftTemplateAsync());
              toast.success('Shift types was successfully updated');
              actions.resetForm();
              setMultiBranch([])
              setEndime(null);
              setStartTime(null);
              setNextStep(0)
              handleClose();
              callBack()
            }
          });
        } else {
          dispatch(createShiftTemplateAsync(value)).then((res) => {

            if (res?.meta?.requestStatus === 'fulfilled' && [200,201].includes(res?.payload?.status)) {
              dispatch(getShiftTemplateAsync());
              toast.success('Creating shift types was successful');
              actions.resetForm();
              setMultiBranch([])
              setEndime(null);
              setStartTime(null);
              setNextStep(0)
              handleClose();
              callBack()
            } else {
              toast.error(res?.payload?.response?.data?.message || "Creating shift types was not successful")
            }
          });
        }
      }
      }, 400);
    },
  });

  useEffect(() => {
    dispatch(fetchJobRolesAsync());
    dispatch(getShiftTemplateAsync());
    dispatch(getBranchesAsync());
    
    const initialBranch = branches?.filter((x) => shiftTemplate?.locations?.map(u => u.location?.id)?.includes(x?.id))?.map((x) => ({
      label: x?.name,
      value: x.id,
      hexcode: x?.hexcode,
      staffRequired: shiftTemplate?.locations?.find((y) => y?.location?.id === x?.id)?.staffRequired,
      location: `${x?.city?.name}, ${x?.state?.code}`
    }))

    setMultiBranch(initialBranch)
    formik.setFieldValue("locations", initialBranch)

    // get days of the week
    if(shiftTemplate?.daysOfWeek){
      const updatedWeek = shiftTemplate?.daysOfWeek?.split(",")?.map((x) => Number(x))
      const data = weekDaysMock?.map((x, index) => {
        return {...x, value: updatedWeek[index]}
      })
      setWeekDay(data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftTemplate?.id, branches.length]);

  const handleInitialState = useCallback(() => {
    if (shiftTemplate?.startTime) {
      setEndime(dayjs(shiftTemplate?.endTime, 'HH:mm'));
      setStartTime(dayjs(shiftTemplate?.startTime, 'HH:mm'));
    }
  }, [shiftTemplate]);

  useEffect(() => {
    handleInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftTemplate]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: '600px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  const handleUpdateShift = () => {
    setShiftTemplate({});
  };

  const handleMultiSelect = (val) => {
    formik.setFieldValue('locations', val)
    setMultiBranch(val);
  };

  const handleEditLocationStaffRequired = (e) => {
    const modifybranch = selectedMultiBranch?.map((x) => {
      if(x.value === e.target.id){
        return {
          ...x,
          staffRequired: e.target.value
        }
      }
      return x
    })

    setMultiBranch(modifybranch)
  }

  const handleShiftDaySelect = (e) => {
    const newDay = weekDays?.map((x) => {
      if(x?.key === Number(e.target.id)){
        return {
          ...x,
          value: x.value === 0 ? 1 : 0
        }
      }
      return x
    })
    setWeekDay(newDay)
  }

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack
        direction="column"
        alignItems="start"
        className="dark:bg-138"
        sx={style}
        gap={3}
      >
        <Typography
          className="dark:text-white"
          sx={{ ...font, fontSize: '18px', fontWeight: '500' }}
        >
          {`${shiftTemplate?.id ? 'Edit' : 'Create'} Shift Template`}
        </Typography>

        {step === 0 && (<TextAndField>
          <Text className="dark:text-white" text={'Template name'} />
          <Field
            text={'Name'}
            type="text"
            value={formik.values.templateName}
            formik={formik}
            sx={{ height: '35px' }}
            name="templateName"
          />
        </TextAndField>)}

        {step === 0 && (<TextAndField>
          <Text className="dark:text-white" text={'Job role'} />
          <FieldSelect
            list={store.map((job) => ({ id: job.id, name: job.title }))}
            value={formik.values.jobRoleId}
            formik={formik}
            height="50px"
            width="100%"
            name="jobRoleId"
          />
        </TextAndField>)}

        {step === 0 && (<MultipleSelectDropdown
          data={branches?.map((branch) => ({
            label: branch?.name,
            value: branch.id,
            hexcode: branch?.hexcode,
            staffRequired: 1,
            location: `${branch?.city?.name}, ${branch?.state?.code}`
          }))}
          parentClass="w-full"
          placeholder="Select locations"
          className="w-full h-[55.5px] dark:border-333"
          value={selectedMultiBranch}
          dropdownTitleClass="text-A5A"
          optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
          setMultiBranch={handleMultiSelect}
        />)}
        {
             step === 0 && formik.errors?.locations && <p className="text-red-500 text-[10px]">{formik.errors?.locations}</p>
            }

        {step === 1 && (<div><p className='text-xs text-center mt-8'>Outline how many staff is required for each location</p></div>)}

        {step === 1 && (<div>
          {
            selectedMultiBranch?.map((x, index) => <div key={index} className="flex items-center mb-2 justify-between">
              <div className="flex items-center gap-2 text-sm"><p>{x?.label}</p><span className="text-xs text-767 ml-4"> {x?.location}</span></div>

              <input min={1} onChange={handleEditLocationStaffRequired} id={x.value} type='number' defaultValue={x?.staffRequired} className="w-[20%] border-b text-center" />

            </div>)
          }
        </div>)}


        {step === 1 && (<div><p className='text-xs text-center mt-[3%]'>{`A total of ${selectedMultiBranch.reduce((prev, curr)=>prev + Number(curr.staffRequired),0)} ${store.find((x) => x.id === formik.values.jobRoleId)?.title} will work accross these locations`}</p></div>)}


        {step === 0 && (<Stack width="100%" direction="row" gap={1}>
          <TextAndField>
            <Text className="dark:text-white" text={'Start Shift'} />
            <TimePickerValue
              width={'130px'}
              value={startTime}
              className={className}
              name="startTime"
              sx={{
                input: {
                  color: darkText,
                },
              }}
              handleChange={(e) => {
                formik.setFieldValue('startTime', e);
                setStartTime(e);
              }}
            />
            {
              formik.errors?.startTime && <p className="text-red-500 text-[10px]">{formik.errors?.startTime}</p>
            }
            {/* <Field
              text={'Choose time'}
              type="time"
              width={'130px'}
              value={formik.values.startTime}
              formik={formik}
              name="startTime"
            /> */}
          </TextAndField>

          <TextAndField>
            <Text className="dark:text-white" text={'End Shift'} />
            <TimePickerValue
              width={'130px'}
              value={endTime}
              className={className}
              handleChange={(e) => {
                formik.setFieldValue('endTime', e);
                setEndime(e);
              }}
              sx={{
                input: {
                  color: darkText,
                },
              }}
              name="endTime"
            />
            {
              formik.errors?.endTime && <p className="text-red-500 text-[10px]">{formik.errors?.endTime}</p>
            }
          </TextAndField>
        </Stack>)}

        {
          step === 2 && (<div className="w-1/2 flex flex-col gap-3 ml-14">
            {weekDays?.map(x => (<div className="flex items-center justify-between"><p>{x?.name}</p> <div onClick={handleShiftDaySelect} id={x.key} className={`w-[15px] h-[15px] rounded-full cursor-pointer ${x.value === 0 ? "bg-red-500" : "bg-green-500 "} `} /></div>))}
          </div>)
        }


        <div className="flex justify-between w-full my-auto mb-0">
          <OutlinedButton
            text={step === 0 ?"Cancel" : "Prev"}
            callBack={() => {
              if(step === 0) {
                handleUpdateShift();
                formik.resetForm();
                setEndime(null);
                setStartTime(null);
                setMultiBranch([])
                handleClose();
              } else {
                
                handlePrevStep()
              }
            }}
          />
          
          {!isLoading && (
            <FillButton
              text={[0,1].includes(step) ? "Next" : shiftTemplate?.id ? 'Update' : 'Create'}
              callBack={ async () => {
                if([0,2].includes(step)){
                  formik.handleSubmit();
                }
                const validate = await formik.validateForm()
                if(Object.keys(validate).length === 0 && [0,1].includes(step)){
                  handleNextStep()
                }
              }}
            />
          )}

          {isLoading && <CircularProgress />}
        </div>
      </Stack>
    </FormModal>
  );
};
