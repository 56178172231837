import { Modal } from "@material-ui/core";
import { Stack } from "@mui/material";
import EmailSentGif from "../../../../Assets/gif/Email-Sent.gif";

export function ForgotPasswordModal({ open = false, handleClose, navigate }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ height: "100vh" }}
        justifyContent="center"
      >
        <div className="px-[20px] flex flex-col rounded-[11px] justify-end gap-y-2 pt-[40px] pb-[20px] bg-white w-[612px] h-[335px] text-center">
          <img
            src={EmailSentGif}
            alt="Email sent animation"
            className="w-[120px] h-[120px] object-contain mx-auto"
          />
          <p className="text-[28px] font-semibold">Reset password link sent</p>
          <p className="text-base text-A6A">
            Reset password link has been sent to your email. Follow the
            instructions to set your password
          </p>
          <p
            onClick={() => navigate("/login")}
            className="text-E42 text-sm font-semibold underline underline-E42 pt-1 cursor-pointer"
          >
            Back to Login Page
          </p>
        </div>
      </Stack>
    </Modal>
  );
}
