import { Storage } from "../StorageClass/StorageClass";
import axios from "axios";
import { conn_url } from "../conn_consts";

const config = {
    headers:{
        "content-type":"application/json"
    }
};

const storage = new Storage();

class SubscriptionService  {
  
  // Get all subscriptions
  
async getAllSubscriptions() {
    const response = await axios.get(conn_url+"/api/v1/company/get-subscription-plans", storage.getConfig());
    return {data: response.data, status:  response.status};
}
// Get all subscriptions
async generateSubscriptionLink(data) {
    const response = await axios.post(conn_url+"/api/v1/company/generate-payment-link", data, storage.getConfig());
    return {data: response.data, status:  response.status};
}

}

export const subscriptionService = new SubscriptionService();

