

export const getMonth = {
  "January": "01",
  "February": "02",
  "March": "03",
  "April": "04",
  "May": "05",
  "June": "06",
  "July": "07",
  "August": "08",
  "September": "09",
  "October": "10",
  "November": "11",
  "December": "12",
}


export const convertToDollar = (val) => (val/100).toFixed(2)

export const checkLinkAccounts = (type) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return Object.values(user?.linkedEmails || {}).map((x) => x.accountType?.toLowerCase())?.includes(type?.toLowerCase())
}

export const setBackgroundByFilter = (shifts, user, skill, gender) =>{

  let data = shifts

  // console.log(gender)

  if(user?.value){
    data = data?.filter((each_data) => each_data?.user?.id === user.value)
  }

  if(skill?.value){
    data = data?.filter((each_data) => (each_data?.user?.userSkillSets?.filter((each_skill) => each_skill.skillSetId === skill.value))?.length > 0 || (each_data?.userSkillSets?.filter((user_skill) => user_skill.skillSetId === skill.value))?.length > 0)
  }

  if(gender?.value){
    data = data?.filter((each_data) => each_data?.user?.gender === gender.value)
  }

  // console.log(data.length, "--data")

  return data?.length > 0

}