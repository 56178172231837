import { useNavigate } from "react-router";
import AppLayout from "../../Widgets/AppLayout";
import { BsCheck } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { subscriptionService } from "../../../Network/ServiceClass/SubscriptionService";
import { getActiveCompany } from "../../../Network/ServiceClass/Company";
import { setActiveCompany } from "../../../Store/Company"; // Assuming you have this action
import { Storage } from "../../../Network/StorageClass/StorageClass";

export const SubscriptionScreen = () => {
  console.log("SubscriptionScreen component rendering");

  const storage = new Storage();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [localActiveCompany, setLocalActiveCompany] = useState(null);
  const reduxActiveCompany = useSelector(
    (state) => state.company.activeCompany,
  );
  const userId = storage.getUserDetails()?.id; // Assuming you store user ID in Redux

  useEffect(() => {
    console.log("useEffect for fetchActiveCompany is running");
    const fetchActiveCompany = async () => {
      console.log("FETCHING ACTIVE COMPANY");
      try {
        if (!userId) {
          console.log("No userId available, skipping fetch");
          return;
        }
        const response = await getActiveCompany(userId);
        if (response) {
          console.log("Response comp >> ", response);
          dispatch(setActiveCompany(response));
          setLocalActiveCompany(response);
        }
      } catch (error) {
        console.error("Error fetching active company:", error);
      }
    };

    fetchActiveCompany();
  }, [userId, dispatch]);

  console.log("USER ID >> ", userId);
  console.log("ACTIVE COMPANY >> ", localActiveCompany);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        setIsLoading(true);
        const fetchedSubscriptions =
          await subscriptionService.getAllSubscriptions();
        const subscription_data = fetchedSubscriptions.data;
        if (Array.isArray(subscription_data)) {
          setSubscriptions(subscription_data);
        } else {
          console.error(
            "Fetched subscriptions is not an array:",
            fetchedSubscriptions,
          );
          setError("Invalid data format received");
        }
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setError("Failed to fetch subscriptions");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleOpenStripe = async (amount, plan, subscriptionObject) => {
    if (amount < 0) {
      navigate("/messages");
      return;
    }

    navigate("/subscriptions-detail", {
      state: {
        amount,
        plan,
        subscriptionObject,
      },
    });
  };

  const features = [
    "Basic shift scheduling",
    "Easy communication",
    "Create & assign tasks",
  ];

  const renderFeature = (feature) => (
    <div key={feature} className="flex items-center gap-1">
      <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57">
        <BsCheck className="text-white font-bold" />
      </div>
      <p className="text-[10px] text-676">{feature}</p>
    </div>
  );

  return (
    <section>
      <AppLayout
        custom="subscriptions"
        subCustom=""
        title="Manage Subscription Plan"
        customTitle=""
        subtitle="Upgrade your productivity with our subscription plan."
        RightComponent={
          <div className="flex items-center justify-center gap-3">
            <button className="bg-D57 text-white rounded-full font-jakarta text-xs font-bold p-2 px-3">
              Monthly
            </button>
            <button className="text-A6A bg-494 font-semibold rounded-full font-jakarta p-2 px-3 text-xs">
              Yearly
            </button>
          </div>
        }
      >
        <div className="grid grid-cols-4 gap-2 mb-40">
          {isLoading ? (
            <p>Loading subscriptions...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : subscriptions.length > 0 ? (
            subscriptions.map((subscription) => {
              const isCurrentPlan =
                subscription.id === localActiveCompany?.subscriptionPlanId;
              return (
                <div
                  key={subscription.id}
                  className="p-3 shadow-cardBox pb-6 rounded-md"
                >
                  <p className="text-lg font-semibold">
                    {subscription.planName}
                  </p>
                  <p className="text-[10px] text-060 mb-4">
                    {subscription.planDescription}
                  </p>
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-D57" />
                    <p className="text-[10px] text-676">
                      Up to{" "}
                      <span className="text-D57 font-semibold">
                        {subscription.maxUsers < 0
                          ? "-"
                          : `${subscription.maxUsers} users`}
                      </span>{" "}
                      allowed
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-D57" />
                    <p className="text-[10px] text-676">
                      Up to{" "}
                      <span className="text-D57 font-semibold">
                        {subscription.maxJobRoles < 0
                          ? "-"
                          : `${subscription.maxJobRoles} job roles`}
                      </span>{" "}
                      allowed
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-D57" />
                    <p className="text-[10px] text-676">
                      Up to{" "}
                      <span className="text-D57 font-semibold">
                        {subscription.maxLocations < 0
                          ? "-"
                          : `${subscription.maxLocations} location${subscription.maxLocations > 1 ? "s" : ""}`}
                      </span>{" "}
                      allowed
                    </p>
                  </div>
                  <p className="mt-7 text-xl font-bold">
                    {subscription.planPrice < 0
                      ? "-"
                      : subscription.planPrice === 0
                        ? "Free"
                        : `$${(subscription.planPrice / 100).toFixed(2)}`}
                    {subscription.planPrice > 0 && (
                      <span className="text-[10px] font-normal text-060">
                        per month
                      </span>
                    )}
                  </p>
                  <button
                    onClick={() =>
                      handleOpenStripe(
                        subscription.planPrice / 100,
                        subscription.planName,
                        subscription,
                      )
                    }
                    className={`border-[0.5px] font-bold rounded-md h-[50px] w-full ${
                      isCurrentPlan
                        ? "border-E42 text-E42"
                        : "text-white bg-E42 border-E42"
                    } my-6`}
                  >
                    {isCurrentPlan
                      ? "Manage Plan"
                      : subscription.planPrice < 0
                        ? "Contact Us"
                        : "Upgrade"}
                  </button>
                  <div className="h-[1px] bg-3F0 w-full mb-5" />
                  <p className="font-bold text-base">Features</p>
                  {features.map(renderFeature)}
                </div>
              );
            })
          ) : (
            <p>No subscriptions available.</p>
          )}
        </div>
      </AppLayout>
    </section>
  );
};
