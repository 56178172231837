import { ReactComponent as OnboardingImg } from "../../../Assets/second_auth.svg";

export function SecondOnboarding() {
  return (
    <div className="flex items-center flex-col justify-center h-full">
      <div className="relative w-full h-[50%]">
        <OnboardingImg className="w-full h-full" />
        <div className="absolute inset-x-0 bottom-0 h-20 bg-gradient-to-t from-[#7E7E7E80]/10 to-transparent" />
      </div>
      <div className="p-10 bg-white">
        <p className="text-[40px] font-semibold pt-5">A New Era of Sync AI</p>
        <p className="text-2xl text-272">
          Say goodbye to lengthy training sessions and constant back-and-forth
          emails. With our AI Q&A feature, your team can get the information
          they need, when they need it, with just a simple question.
        </p>
        <div className="mt-2 flex items-center gap-2">
          <div className="h-2 w-4 bg-7E0 rounded-3xl" />
          <div className="w-[39px] h-[8px] bg-D57 rounded-3xl" />
          <div className="h-2 w-4 bg-7E0 rounded-3xl" />
          <div />
        </div>
      </div>
    </div>
  );
}
