import AppLayout from "../../Widgets/AppLayout";
import { DashboardCard } from "./DashboardCard";
import { ReactComponent as ProfileIcon } from "../../../Assets/getstarted/profile.svg";
import { ReactComponent as EmployeeIcon } from "../../../Assets/getstarted/employee.svg";
import { ReactComponent as LocationIcon } from "../../../Assets/getstarted/location.svg";
import { ReactComponent as ContactIcon } from "../../../Assets/getstarted/contact.svg";
import { ReactComponent as PlanIcon } from "../../../Assets/getstarted/plan.svg";
import { ReactComponent as GoogleIcon } from "../../../Assets/getstarted/google.svg";
import { ReactComponent as jobRoleIcon } from "../../../Assets/getstarted/job_role.svg";
import { ReactComponent as ShiftTemplateIcon } from "../../../Assets/getstarted/shift_template.svg";
import CreateBranchModal from "../BranchScreen/Tabs/Branches/Modal/CreateBranchModal";
import { useState } from "react";
import { useNavigate } from "react-router";
import TeamMemberModal from "../BranchScreen/Tabs/TeamMembers/Modal/TeamMemberModal";
import { NewJobRoleModal } from "../JobsScreen/Modal/NewJobRoleModal";
import { CreateShiftTemplate } from "../ShiftScreen/Tabs/ShiftTemplate/Widget/CreateShiftTemplate";
import WatchDemo from "../../Widgets/DemoWidget";

export function GetStarted() {
  const [isBranchOpen, setOpenBranch] = useState(false);
  const [isMemberOpen, setOpenMember] = useState(false);
  const [isJobroleOpen, setOpenJobrole] = useState(false);
  const [isShiftTemplateOpen, setOpenShifttemplate] = useState(false);
  const navigate = useNavigate();

  const handleOpenShiftTemplate = () => {
    setOpenShifttemplate(true);
  };

  const handleCloseShiftTemplate = () => {
    setOpenShifttemplate(false);
  };

  const handleClose = () => {
    setOpenBranch(false);
  };

  const handleOpen = () => {
    setOpenBranch(true);
  };

  const handleOpenJobrole = () => {
    setOpenJobrole(true);
  };

  const handleCloseJobrole = () => {
    setOpenJobrole(false);
  };

  const handleAddMemberClose = () => {
    setOpenMember(false);
  };

  const handleOpenMember = () => {
    setOpenMember(true);
  };

  const handleCallBack = () => {
    navigate("/branches");
  };

  const handleJobRoleCallBack = () => {
    navigate("/branches");
  };

  const handleShiftTemplateCallBack = () => {
    navigate("/templates");
  };

  return (
    <AppLayout
      custom=""
      subCustom="getting-started"
      title="Welcome to SuprSync!"
      customTitle=" "
      subtitle="We're delighted to have you on board and are excited to help you streamline your workflow. To ensure you get the most out of our platform and access all of our features, we kindly ask you to take a moment to update your profile."
      renderLiveUsers={false}
    >
      <section className="grid grid-cols-3 gap-5">
        <DashboardCard
          title="Create your first Location"
          desc="Set up your  company location to effortlessly assign job roles to your employees."
          directLink="/branches"
          btnTitle="Create"
          onClick={handleOpen}
          asset={LocationIcon}
        />
        <DashboardCard
          title="Invite your Employee"
          desc="Invite your employees to join your SuperSync account for simplified scheduling."
          directLink="/branches"
          btnTitle="Invite"
          asset={EmployeeIcon}
          onClick={handleOpenMember}
        />

        <DashboardCard
          title="Set up your Profile"
          desc="Set up with relevant information such as profile picture, skill set, number of employees, etc. "
          directLink="/profile-page"
          btnTitle="Profile"
          asset={ProfileIcon}
        />

        <DashboardCard
          title="Create Job role"
          desc="Create your company job role to effortlessly assign shifts to your employees."
          directLink="/jobs"
          asset={jobRoleIcon}
          btnTitle="Create"
          footTitle="Learn More"
          onClick={handleOpenJobrole}
        />
        <DashboardCard
          title="Create Shift Template"
          desc="Create reusable shift templates to streamline scheduling and ensure consistent coverage."
          directLink="/templates"
          asset={ShiftTemplateIcon}
          btnTitle="Create"
          footTitle="Learn More"
          onClick={handleOpenShiftTemplate}
        />

        <DashboardCard
          title="Contact Us"
          desc="Contact us via live chat. Don't hesitate to reach out if you need assistance navigating SuprSync."
          directLink="/messages"
          asset={ContactIcon}
          showbtn={false}
          footTitle="Open Live Chat"
          darkMode={false}
        />

        <DashboardCard
          title="Upgrade Plan"
          desc="You are currently on our free trial. Manage your plan and upgrade to a premium plan for additional features."
          directLink="/subscriptions"
          asset={PlanIcon}
          showbtn={false}
          darkMode={false}
          footTitle="Upgrade Plan"
        />
        <DashboardCard
          title="Sync your Google Mail"
          desc="Sync your Google Mail with SuprSync to effortlessly access your schedules and see your availability at a glance."
          directLink="/profile-page"
          asset={GoogleIcon}
          showbtn={false}
          darkMode={false}
          footTitle="Synch Google Mail"
        />
      </section>
      <WatchDemo />
      {isBranchOpen && (
        <CreateBranchModal
          open={isBranchOpen}
          handleClose={handleClose}
          callback={handleCallBack}
        />
      )}
      {isMemberOpen && (
        <TeamMemberModal
          open={isMemberOpen}
          handleClose={handleAddMemberClose}
          callback={handleCallBack}
        />
      )}

      {isShiftTemplateOpen && (
        <CreateShiftTemplate
          open={isShiftTemplateOpen}
          handleClose={handleCloseShiftTemplate}
          callBack={handleShiftTemplateCallBack}
        />
      )}

      {isJobroleOpen && (
        <NewJobRoleModal
          open={isJobroleOpen}
          handleClose={handleCloseJobrole}
          callback={handleJobRoleCallBack}
        />
      )}
    </AppLayout>
  );
}
