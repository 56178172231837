import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();

export const setCompanyLogo = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/company/logo`,data, storage.getFileConfig());
    return response.data;
  } catch (error) {
    return error?.response;
  }
}

export const getUnavailableDays = async (data) => {
  try {
    let queryString = '';
    Object.keys(data).map((x) => {
      if(data[x]){
        queryString += `/${data[x]}`
      }
      return null;
    })
    
    const response = await axios.get(`${conn_url}/api/v1/getblockdays${queryString}`, storage.getConfig());
    return response.data;
  } catch (error) {
    return error?.response;
  }
}

export const blockDays = async (data) => {
  try {
    const response = await axios.post(`${conn_url}/api/v1/blockdays`,data, storage.getConfig());
    return response.data;
  } catch (error) {
    return error?.response;
  }
}

export const unBlockDays = async ({date = ''}) => {
  try {
    const response = await axios.delete(`${conn_url}/api/v1/unblock/${date}`, storage.getConfig());
    return response.data;
  } catch (error) {
    return error?.response;
  }
}

export const adminUnBlockDays = async ({date = '', userId, companyId}) => {
  try {
    const response = await axios.delete(`${conn_url}/api/v1/admin-unblock-staffDay/${userId}/${companyId}/${date}`, storage.getConfig());
    return response.data;
  } catch (error) {
    return error?.response;
  }
}

export const getCompanyRotationRecord = async () => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/company-rotation-data`, storage.getFileConfig());
    return response.data;
  } catch (error) {
    return error?.response;
  }
}

export const getActiveCompany = async (userId) => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/users/get-active-company?userId=${userId}`, storage.getConfig());
    return response.data;
  } catch (error) {
    return error?.response;
  }
}

export const getSubscriptionPlans = async () => {
  try {
    const response = await axios.get(`${conn_url}/api/v1/company/get-subscription-plans`, storage.getFileConfig());
    return response.data;
  } catch (error) {
    return error?.response;
  }
}
